import React, { useEffect } from 'react';
import { LandingPage, PaymentsAndPackages, StockAnalysis} from '../pages';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoadingFullScreen } from '../components';

const ProtectedRoute = ({component, label }) => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const params = new URLSearchParams(window.location.search)
  const error = params.get("error")
  const navigate = useNavigate();
  const { hasLoggedOut } = useSelector((state) => state.userPreferenceReducer);
  const { stripeSubscriptionIdRedux, subscriptionStatus, customerCards } = useSelector((state) => state.watchListReducer);
  const stripeSubscriptionId = localStorage.getItem('stripeSubscriptionId');


  useEffect(()=>{
  },[isAuthenticated, isLoading, stripeSubscriptionIdRedux])
  if(hasLoggedOut){
    return <LoadingFullScreen text={"Logging you out"}/>;
  }
  else if(!isLoading && !hasLoggedOut){
    if(isAuthenticated && user.email_verified){
      if (!stripeSubscriptionId) {
        return <PaymentsAndPackages />;
      } else if (subscriptionStatus === 'trialing') {
        if (!customerCards.id)
        return <PaymentsAndPackages />;
      } else if (subscriptionStatus !== 'active') {
        return <PaymentsAndPackages />;
      }
        
      if(label==="LandingPage" && localStorage.getItem('token')){
        navigate("/stockanalysis");
        return <StockAnalysis/>;
      }
      else
        return component;
    } else {
      window.history.replaceState(null, document.title, "/");
      if(error){
        return <LandingPage error={error}/>
      }
      return <LandingPage/>;
    }
  }
}

export default ProtectedRoute;
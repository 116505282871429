// External imports
import React, { useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { useMediaQuery } from 'react-responsive'
import { useAuth0 } from '@auth0/auth0-react'
import { BsBookmarks, BsGrid, BsBarChart, BsFunnel, BsChevronDoubleLeft, BsChevronDoubleRight, BsSun, BsPerson, BsCalendar3, BsList } from 'react-icons/bs'
import { IoLogOutOutline } from 'react-icons/io5'

// Internal imports
import { LogoImage, LogoText } from '../../components/index'
import { setDisplayTheme } from '../../actions/userPreferences'

//Styling
const NavbarContainer = styled.nav`${tw` w-[100%] flex justify-between items-center bg-primaryBg border-textBase border-b-1`};`
const NavlinkContainer = styled.div`${tw`min-w-[fit-content] w-1/5 flex flex-row justify-center `}`
const LeftContainer = styled.div`${tw`min-w-[fit-content] w-2/5 flex flex-row justify-start`}`
const RightContainer = styled.div`${tw`min-w-[fit-content] w-2/5 flex flex-row justify-end items-center pr-2`}`
const ExpandedText = styled.div` ${tw`whitespace-pre leading-6 transition-opacity ease-in-out duration-500`}`
const Tooltip = styled.h2` ${tw`fixed left-16 bg-activeBg whitespace-pre text-primaryBg rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden h-[30px] ml-2`}`

const UnderlinedLink = styled(Link)`
  ${tw`relative tracking-widest font-semibold mx-3`}
  ${tw`after:absolute after:bg-textBase after:left-0 after:right-[100%] after:bottom-[-2px] after:h-[2px] after:transition-[right] after:duration-[300ms] after:ease-in-out`}
  ${tw`hover:after:right-0`}
`

const LinkButton = styled(Link)`
  ${tw`my-2 flex items-center gap-3.5 font-medium p-2 rounded-md transition-transform ease-in-out duration-500`};
  ${tw`hover:bg-neutralBg hover:text-textBase`}
`

const ExpandButton = styled.button`
  ${tw`absolute top-[90px] right-[-12px] w-[24px] h-[24px] flex items-center justify-center rounded bg-primaryBg border-1 border-textBase cursor-pointer text-xs text-textMuted`};
  ${tw`hover:text-sm hover:text-textBase`}
`

const useCommonMenu = (toggleTheme, doLogout) => {
  return [
    // { id: uuidv4(), name: 'Portfolio', link: '/portfolio', icon: BsGrid },
    { id: uuidv4(), name: 'Analysis', link: '/stockanalysis', icon: BsBarChart },
    { id: uuidv4(), name: 'Watch List', link: '/watchList', icon: BsBookmarks },
    { id: uuidv4(), name: 'Screener', link: '/screener', icon: BsFunnel },
    { id: uuidv4(), name: 'Calendar', link: '/calendar', icon: BsCalendar3 },
    { id: uuidv4(), name: 'Theme', link: '#', icon: BsSun, onClick: toggleTheme },
    { id: uuidv4(), name: 'Account', link: '/account', icon: BsPerson },
    { id: uuidv4(), name: 'Logout', link: '#', icon: IoLogOutOutline, onClick: doLogout },
  ];
};

const menuIconSliceNumber = 4;

const useMenuActions = (dispatch, logout) => {
  const toggleTheme = () => {
    const newTheme = localStorage.getItem('theme') === 'light' ? 'dark' : 'light';
    dispatch(setDisplayTheme(newTheme));
  };

  const doLogout = () => {
    dispatch({ type: 'SET_LOGOUT', payload: true });
    localStorage.removeItem('token');
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return { toggleTheme, doLogout };
};

export const Navbar = () => {
  const { logout } = useAuth0()
  const dispatch = useDispatch()
  const location = useLocation()
  const showSummarisedMenu = useMediaQuery({ maxWidth: '650px' })
  const { toggleTheme, doLogout } = useMenuActions(dispatch, logout);
  const menus = useCommonMenu(toggleTheme, doLogout);
  const menuSummarised = [
    { id: uuidv4(), name: 'Menu', link: '/menu', icon: BsList },
  ]

  const renderMenuItems = (menusToRender) => {
    return menusToRender.map((menu) => (
      <LinkButton 
        key={menu.id} 
        to={menu.link} 
        onClick={menu.onClick}
        className={`${location.pathname.startsWith(menu.link) ? 'bg-positiveBg text-textBase pointer-events-none' : ''}`}
      >
        <div>{React.createElement(menu.icon, { size: '24' })}</div>
      </LinkButton>
    ))
  }

  return (
    <NavbarContainer>
      <LeftContainer>
        <LogoImage />
        <LogoText />
      </LeftContainer>

      <NavlinkContainer>
        <div className="text-textMuted flex flex-row space-x-2">
          {renderMenuItems(menus.slice(0, menuIconSliceNumber))}
        </div>
      </NavlinkContainer>

      <RightContainer>
        {!showSummarisedMenu && (
          <div className="text-textMuted flex flex-row space-x-2">
            {renderMenuItems(menus.slice(menuIconSliceNumber))}
          </div>
        )}
        {showSummarisedMenu && (
          <div className="text-textMuted">
            {renderMenuItems(menuSummarised.slice(0, 1))}
          </div>
        )}
      </RightContainer>
    </NavbarContainer>
  )
}

export const Sidebar = () => {
  const { logout } = useAuth0()
  const dispatch = useDispatch()
  const location = useLocation()
  const { toggleTheme, doLogout } = useMenuActions(dispatch, logout);
  const [open, setOpen] = useState(false)
  const menus = useCommonMenu(toggleTheme, doLogout);

  const renderMenuItems = (menusToRender) => {
    return menusToRender.map((menu) => (
      <LinkButton 
        key={menu.id} 
        to={menu.link} 
        onClick={menu.onClick}
        className={`${open ? '' : 'justify-left'} ${location.pathname.startsWith(menu.link) ? 'bg-positiveBg text-textBase pointer-events-none' : ''} group`}
      >
        <div>{React.createElement(menu.icon, { size: '24' })}</div>
        <ExpandedText className={`${!open && 'hidden opacity-0 translate-x-28 overflow-hidden'}`}>
          {menu.name}
        </ExpandedText>
        <Tooltip className={`${open && 'hidden'} group-hover:w-fit group-hover:px-2 group-hover:py-1 group-hover:left-16 flex items-center`}>
          {menu.name}
        </Tooltip>
      </LinkButton>
    ))
  }

  return (
    <section className="flex gap-6 bg-primaryBg border-textBase border-r-1">
      <div className={`min-h-screen ${open ? 'w-40' : 'w-16'} duration-100 text-textBase px-3 flex flex-col justify-start`}>
        <div className="flex flex-col gap-4 items-center justify-center py-2 border-b-1 border-neutral">
          <LogoImage />
        </div>
        <ExpandButton type='button' onClick={() => setOpen(!open)}>
          {open ? <BsChevronDoubleLeft /> : <BsChevronDoubleRight />}
        </ExpandButton>
        <div className="pt-10 flex flex-col gap-4 justify-between md:h-4/5 2xl:h-5/6 grow text-xs">
          <div className="text-textMuted">
            {renderMenuItems(menus.slice(0, menuIconSliceNumber))}
          </div>
          <div className="mb-2 text-textMuted">
            {renderMenuItems(menus.slice(menuIconSliceNumber))}
          </div>
        </div>
      </div>
    </section>
  )
}

export const LandingPageNavbar = ({className = 'absolute top-0 z-10 border-b-1'}) => {
  const { loginWithRedirect } = useAuth0()
  const navigate = useNavigate()
  return (
    <NavbarContainer className={className}>
      <div className="w-fit flex flex-row justify-start cursor-pointer" onClick={() => navigate('/')}>
        <LogoImage />
        <LogoText />
      </div>
      <RightContainer>
        <UnderlinedLink onClick={() => loginWithRedirect()}>
          Log In
        </UnderlinedLink>
        <UnderlinedLink to={'/signup-terms'}>Sign Up</UnderlinedLink>
      </RightContainer>
    </NavbarContainer>
  )
}
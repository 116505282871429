import React from 'react';
import { ScaleLoader } from 'react-spinners';

export const LoadingFullScreen = ({ text }) => {

  return (
  <div className='flex justify-center items-center h-screen'> 
    <ScaleLoader size={50} color={'#218B82'}/>
    <p className='pl-6'>{text}</p>
  </div>
  )
}

export default LoadingFullScreen;
// External imports
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { IoNotificationsOutline } from 'react-icons/io5'
import { MdOutlineClose } from 'react-icons/md'

// Internal imports
import {
  ContentContainer,
  ToggleButton,
  IconButtons,
  SelectedItemCard,
  NotificationsModal,
  TextOptionSelector,
} from '../../components'
import { getNameConversion } from '../../utils'


// Styling
export const Container = styled.div`${tw`w-full flex flex-col items-center m-6`};`
const VerticalContainer = styled.div`${tw`w-full flex flex-col text-sm divide-y divide-neutral space-y-2`};`
const InfoContainer = styled.div`${tw`w-full flex pt-2`};`
const InfoItemLabel = styled.div`${tw`w-full flex flex-col items-start justify-center text-lg font-semibold`};`

export const activeNotifications = [
  {
    ticker: 'AAPL',
    notifications: [
      {
        price: {
          value: 100,
          isActive: true,
        },
        oneDayPriceChangePercentage: {
          value: 0.1,
          isActive: false,
        },
        weeklyPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        monthlyPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        quarterlyPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        halfyearPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        yearlyPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        fiveYearPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        peRatio: {
          value: 0,
          isActive: false,
        },
        dividendYieldTTM: {
          value: 0,
          isActive: false,
        },
        freeCashFlowYieldTTM: {
          value: 0,
          isActive: false,
        },
        declarationDate: {
          isActive: false,
        },
        paymentDate: {
          isActive: true,
        },
        earningsCalendar: {
          isActive: true,
        },
      },
    ],
  },
  {
    ticker: 'POOL',
    notifications: [
      {
        price: {
          value: 100,
          isActive: true,
        },
        oneDayPriceChangePercentage: {
          value: 0.1,
          isActive: true,
        },
        weeklyPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        monthlyPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        quarterlyPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        halfyearPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        yearlyPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        fiveYearPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        peRatio: {
          value: 0,
          isActive: false,
        },
        dividendYieldTTM: {
          value: 0,
          isActive: false,
        },
        freeCashFlowYieldTTM: {
          value: 0,
          isActive: false,
        },
        declarationDate: {
          isActive: false,
        },
        paymentDate: {
          isActive: true,
        },
        earningsCalendar: {
          isActive: true,
        },
      },
    ],
  },
  {
    ticker: 'GOOG',
    notifications: [
      {
        price: {
          value: 100,
          isActive: true,
        },
        oneDayPriceChangePercentage: {
          value: 0.1,
          isActive: true,
        },
        weeklyPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        monthlyPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        quarterlyPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        halfyearPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        yearlyPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        fiveYearPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        peRatio: {
          value: 0,
          isActive: false,
        },
        dividendYieldTTM: {
          value: 0,
          isActive: false,
        },
        freeCashFlowYieldTTM: {
          value: 0,
          isActive: false,
        },
        declarationDate: {
          isActive: false,
        },
        paymentDate: {
          isActive: true,
        },
        earningsCalendar: {
          isActive: false,
        },
      },
    ],
  },
  {
    ticker: 'MSFT',
    notifications: [
      {
        price: {
          value: 100,
          isActive: false,
        },
        oneDayPriceChangePercentage: {
          value: 0.1,
          isActive: false,
        },
        weeklyPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        monthlyPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        quarterlyPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        halfyearPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        yearlyPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        fiveYearPricePercentageChange: {
          value: 0,
          isActive: false,
        },
        peRatio: {
          value: 0,
          isActive: false,
        },
        dividendYieldTTM: {
          value: 0,
          isActive: false,
        },
        freeCashFlowYieldTTM: {
          value: 0,
          isActive: false,
        },
        declarationDate: {
          isActive: false,
        },
        paymentDate: {
          isActive: true,
        },
        earningsCalendar: {
          isActive: true,
        },
      },
    ],
  },
]

export const PathHeader = ({ icon, pageName }) => (
  <>
    <div>{icon}</div>
    <div className="ml-2">{pageName}</div>
  </>
)

const SettingsSingleRowContainer = ({ header, info, option }) => (
  <InfoContainer className="flex-row items-center justify-between pt-2">
    <InfoItemLabel>{header}</InfoItemLabel>
    <div>{info}</div>
    <div>{option}</div>
  </InfoContainer>
)

const SettingsToggleTable = ({ header, options, selectedOptions, setSelectedOptions }) => {
  const toggleOption = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(
        selectedOptions.filter((selectedOption) => selectedOption !== option)
      )
    } else {
      setSelectedOptions([...selectedOptions, option])
    }
  }

  return (
    <InfoContainer className="flex-col items-start justify-center pb-2">
      <InfoItemLabel>{header}</InfoItemLabel>
      <div className="w-full">
        <div className="space-y-1">
          {options.map((option, index) => (
            <div
              key={index}
              className="w-full flex flex-row items-center justify-between"
            >
              {option.isOptional && (
                <>
                  <div>{option.displayedText}</div>
                  <div className="flex flex-row items-center">
                    <ToggleButton
                      isActive={selectedOptions.includes(option.displayedText)}
                      onClick={() => toggleOption(option.displayedText)}
                    />
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </InfoContainer>
  )
}

export const Notifications = () => {
  const [selectedNotificationTypes, setSelectedNotificationTypes] = useState([])
  const [selectedNotificationFrequencies, setSelectedNotificationFrequencies] =
    useState('Daily')
  const [openModalForTicker, setOpenModalForTicker] = useState({})

  const NotificationType = [
    { displayedText: 'Push', isOptional: true },
    { displayedText: 'Email', isOptional: true },
    { displayedText: 'SMS', isOptional: true },
  ]

  const NotificationFrequency = ['Daily', 'Weekly', 'Monthly']

  const clearNotificationOption = (option) => {
    return null
    // Set value = 0 if there is a value;
    // change active to false;
  }

  return (
    <Container>
      <ContentContainer
        Header={
          <PathHeader icon={<IoNotificationsOutline />} pageName={'Notifications'}/>
        }
        Icons={
          <Link to="/account">
            <IconButtons icon={<MdOutlineClose />} />
          </Link>
        }
        maxWidth={'800px'}
        Content={
          <VerticalContainer>
            <SettingsToggleTable
              header={'Notification type'}
              options={NotificationType}
              selectedOptions={selectedNotificationTypes}
              setSelectedOptions={setSelectedNotificationTypes}
              type={'toggle'}
            />
            <SettingsSingleRowContainer
              header={'Notification frequency'}
              option={
                <TextOptionSelector
                  options={NotificationFrequency}
                  selection={selectedNotificationFrequencies}
                  setSelection={setSelectedNotificationFrequencies}
                />
              }
            />
            <InfoContainer className="flex flex-col">
              <InfoItemLabel>Existing notifications</InfoItemLabel>
              <div className="max-h-[400px] overflow-y-auto space-y-2">
                {activeNotifications.map(({ ticker, notifications }, index) => (
                  <div
                    key={index}
                    className="flex flex-row items-center border-1 rounded p-2 min-h-[46px]"
                  >
                    <div className="flex flex-row items-center w-[125px]">
                      <img
                        src={`https://financialmodelingprep.com/image-stock/${ticker}.png`}
                        width="30"
                        height="30"
                        className="my-2 mr-2"
                        alt="stock logo"
                        type="image/png"
                        style={{
                          filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4))',
                          borderRadius: '3px',
                        }}
                      />
                      <h1 className="text-lg font-semibold">{ticker}</h1>
                    </div>
                    <div className="w-full flex flex-row flex-wrap space-x-2 p-2 bg-neutralBg rounded">
                      {notifications.flatMap((notification) =>
                        Object.entries(notification)
                          .filter(([, valueObj]) => valueObj.isActive)
                          .map(([key, valueObj]) => {
                            const valueDisplay =
                              valueObj.value !== undefined
                                ? `= ${valueObj.value}`
                                : ''
                            return (
                              <SelectedItemCard
                                key={key}
                                selection={
                                  <div className="space-x-1 flex flex-row h-full">
                                    <div className="p-2">
                                      {getNameConversion(key)}
                                    </div>
                                    <div className="h-[30px] w-[2px] bg-neutralBg" />
                                    <div className="p-2">{valueDisplay}</div>
                                  </div>
                                }
                                closeAction={() =>
                                  clearNotificationOption(notification)
                                }
                              />
                            )
                          })
                      )}
                    </div>
                    <IconButtons
                      action={() =>
                        setOpenModalForTicker((prevState) => ({
                          ...prevState,
                          [ticker]: true,
                        }))
                      }
                      icon={<IoNotificationsOutline />}
                      text={'Notifications'}
                    />
                    <NotificationsModal
                      open={openModalForTicker[ticker]}
                      close={() =>
                        setOpenModalForTicker((prevState) => ({
                          ...prevState,
                          [ticker]: false,
                        }))
                      }
                      icon={<IoNotificationsOutline />}
                      header="Notifications"
                      ticker={ticker}
                    />
                  </div>
                ))}
              </div>
            </InfoContainer>
          </VerticalContainer>
        }
      />
    </Container>
  )
}
// External imports
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useSelector } from 'react-redux'
import { ScaleLoader } from 'react-spinners'
import dayjs from 'dayjs'

// Internal imports
import { Modal, Graph } from '../../../components/'
import { renderLoadingOrContent, getCategoryIcon } from '../../../utils/'
import { useGetHistoricEmployeeCountQuery } from '../../../rtk';

// Styling
const SubHeaderText = styled.h1`
  ${tw`w-[fit-content] font-semibold mb-2 flex flex-row items-center`}
`
const ParagraphWrapper = styled.div`
  ${tw`w-full text-sm text-textMuted max-h-[500px] overflow-hidden text-left`};
  display: -webkit-box;
  -webkit-line-clamp: 25;
  -webkit-box-orient: vertical;
`

const MainContainer = styled.div`
  ${tw`w-full overflow-y-auto mt-2 space-y-2 overflow-x-hidden`};
`

const GridContainer = styled.div`
  ${tw`w-full grid justify-between border-1 border-neutral rounded p-2 gap-x-10 gap-y-2`};
  @media (min-width: 700px) {
    ${(props) =>
      props.layout === 'details' ? tw`grid-cols-4` : tw`grid-cols-3`};
  }
  @media (max-width: 699px) {
    ${(props) =>
      props.layout === 'details' ? tw`grid-cols-2` : tw`grid-cols-2`};
  }
  @media (max-width: 449px) {
    ${(props) =>
      props.layout === 'details' ? tw`grid-cols-1` : tw`grid-cols-1`};
  }
`

const DetailsWrapper = ({ maxWidth, category, header, content }) => {
  return (
    <div className="w-full" style={{ maxWidth: maxWidth }}>
      <SubHeaderText>
        {getCategoryIcon(category)}
        {header}
      </SubHeaderText>
      <ParagraphWrapper>
        {renderLoadingOrContent(content, !content)}
      </ParagraphWrapper>
    </div>
  )
}

const CompanyDetails = ({ searchedStockData }) => {
  return (
    <GridContainer layout="details">
      <DetailsWrapper
        category="exchangeShortName"
        header="Exchange:"
        content={searchedStockData?.profile?.exchangeShortName}
      />
      <DetailsWrapper
        category="sector"
        header="Sector:"
        content={searchedStockData?.profile?.sector}
      />
      <DetailsWrapper
        category="industry"
        header="Industry:"
        content={searchedStockData?.profile?.industry}
      />
      <DetailsWrapper
        category="date"
        header="Year end:"
        content={searchedStockData.fiscalYearEnd}
      />
    </GridContainer>
  )
}

const BusinessDescription = ({ description }) => {
  return (
    <div className="h-full border-1 border-neutral rounded p-2">
      <DetailsWrapper
        category="details"
        header="Business description:"
        content={description}
      />
    </div>
  )
}

const ContactDetails = ({ searchedStockData, formattedIpoDate }) => {
  return (
    <GridContainer>
      <DetailsWrapper
        category="contact"
        header="Contact:"
        content={
          <div>
            {renderLoadingOrContent(
              searchedStockData?.profile?.address,
              !searchedStockData?.profile?.address
            )}
            {renderLoadingOrContent(
              <div>
                {searchedStockData?.profile?.city}, {searchedStockData?.profile?.state},{' '}
                {searchedStockData?.profile?.zip}
              </div>,
              !searchedStockData?.profile?.city
            )}
            {renderLoadingOrContent(
              searchedStockData?.profile?.country,
              !searchedStockData?.profile?.country
            )}
            {renderLoadingOrContent(
              <div>T: {searchedStockData?.profile?.phone}</div>,
              !searchedStockData?.profile?.phone
            )}
            {renderLoadingOrContent(
              searchedStockData?.profile?.website,
              !searchedStockData?.profile?.website
            )}
          </div>
        }
      />
      <DetailsWrapper
        maxWidth="170px"
        category="person"
        header="CEO:"
        content={searchedStockData?.profile?.ceo}
      />
      <DetailsWrapper
        category="date"
        header="IPO:"
        content={formattedIpoDate}
      />
    </GridContainer>
  )
}

const EmployeeDetails = ({ symbol }) => {

  const { data: employeeCountData, isLoading: isEmployeeCountLoading } = useGetHistoricEmployeeCountQuery(symbol);

  const chartData = !isEmployeeCountLoading && employeeCountData?.data?.slice(0, 20).reverse().map((obj) => ({
    date: dayjs(obj.periodOfReport).format('DD MMM YYYY'),
    count: obj.employeeCount
  })) || [];

  return (
    <div className="w-full border-1 border-neutral rounded p-2">
      <SubHeaderText>{getCategoryIcon('people')}Employees:</SubHeaderText>
      <div className="h-fit"> 
        {chartData && !isEmployeeCountLoading ? (
          <Graph
            statType={'Employees'}
            chartData={[
              {
                name: 'Employees',
                data: chartData.map((item) => item.count),
                labels: chartData.map((item) => item.date),
              },
            ]}
            chartType={'bar'}
            chartColours={['#da7073']}
            displayLegend={false}
            tickRounding="1"
            dataRounding="2"
          />
        ) : null} 
        {isEmployeeCountLoading && (
          <div className="flex justify-center items-center h-[150px]">
            <ScaleLoader size={40} color={'#218B82'} />
          </div>
        )} 
      </div>
    </div>
  )
}

// Modal with general information on the company
export const CompanyInfo = ({ open, closeModal, icon, symbol }) => {
  const [dom, setDom] = useState(null)
  const [isYScrollable, setIsYScrollable] = useState(false)
  const { searchedStockData } = useSelector((state) => state.stockReducer)
  const ipoDate = searchedStockData?.profile?.ipoDate
    ? new Date(searchedStockData?.profile?.ipoDate)
    : null
  const formattedIpoDate = ipoDate
    ? ipoDate.toLocaleDateString('en-UK', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })
    : null

  const initDom = (node) => {
    if (node) setDom(node)
  }

  useEffect(() => {
    const handler = () => {
      if (dom && dom.scrollHeight > dom.clientHeight) {
        setIsYScrollable(true)
      } else {
        setIsYScrollable(false)
      }
    }
    let ignore = false
    const func = () => {
      if (dom && dom.scrollHeight > dom.clientHeight && !ignore) {
        setIsYScrollable(true)
      } else if (!ignore) {
        setIsYScrollable(false)
      }
    }

    func()

    window.addEventListener('resize', handler)
    return () => {
      ignore = true
      window.removeEventListener('resize', handler)
    }
  }, [dom, searchedStockData])

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      headerContent={
        <h1 className="flex flex-row items-center space-x-2">
          {icon}
          <span>Company Info</span>
        </h1>
      }
      height="fit-content"
      maxWidth={'800px'}
      content={
        <>
          <MainContainer
            className={`max-h-[calc(90vh_-_110px)] ${
              isYScrollable ? 'pr-2' : 'pr-0'
            }`}
            ref={initDom}
          >
            <CompanyDetails searchedStockData={searchedStockData} />
            <BusinessDescription description={searchedStockData?.profile?.description} />
            <ContactDetails
              searchedStockData={searchedStockData}
              formattedIpoDate={formattedIpoDate}
            />
            <EmployeeDetails searchedStockData={searchedStockData} symbol={symbol} />
          </MainContainer>
        </>
      }
    />
  )
}

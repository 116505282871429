// External Imports
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useDispatch, useSelector } from 'react-redux'
import { BsBookmarkHeart, BsToggles } from 'react-icons/bs'
import _ from 'lodash'

// Internal Imports
import {
  ContentContainer,
  IconButtons,
  DataTable,
  OptionsModal,
} from '../../components'
import { metricOptions } from '../../data/metricOptions'
import { updateTableMetrics } from '../../actions/userPreferences'
import { Link } from 'react-router-dom'
import { ScaleLoader } from 'react-spinners'
import { useFetchWatchlistDataQuery, useFetchWatchlistQuery } from '../../rtk'

//Styling
const Container = styled.div`
  ${tw`w-full flex flex-col items-center p-6`};
`
const WarningWrapper = styled.div`
  ${tw`flex flex-col items-center justify-items-center p-5`};
`
const WarningText = styled.div`
  ${tw`text-center pt-3`};
`

// Page containing the users watch list
export const WatchList = () => {
  const dispatch = useDispatch()
  const { tableMetrics, tableRows } = useSelector(
    (state) => state.userPreferenceReducer
  )
  const [tableData, setTableData] = useState([])
  const [openSettingsModal, setOpenSettingsModal] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState(tableMetrics)
  const [dataTableTotalPage, setDataTableTotalPage] = useState(0)
  const [datatableCurrentPage, setDatatableCurrentPage] = useState(1)
  const [sortField, setSortField] = useState('symbol')
  const [sortOrder, setSortOrder] = useState(1)

  const { data: watchlistSymbols, isFetching: isFetchingWatchlistSymbols } =
    useFetchWatchlistQuery()

  const { data, isFetching, refetch } = useFetchWatchlistDataQuery({
    tableMetrics,
    page: datatableCurrentPage,
    sortField,
    sortOrder,
    tableRows,
  })
  useEffect(() => {
    if (data && data.data && !isFetchingWatchlistSymbols && !isFetching) {
      if (!data.data.data.length && datatableCurrentPage > 1) {
        setDatatableCurrentPage(datatableCurrentPage - 1)
      } else {
        setTableData(data.data.data ?? [])
        setDataTableTotalPage(data.data.totalPages ?? 0)
      }
    }
  }, [data, datatableCurrentPage, isFetching, isFetchingWatchlistSymbols])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const groupedOptions = metricOptions.reduce((result, option) => {
    if (option.isOptional) {
      if (!result[option.category]) {
        result[option.category] = []
      }
      result[option.category].push(option)
    }
    return result
  }, {})

  const hasChanged = (metrics, options) => {
    if (tableMetrics.length !== selectedOptions.length) {
      return true
    }

    return !metrics.every((item) => options.includes(item))
  }
  return (
    <Container>
      <ContentContainer
        Header={'Watch List'}
        hasExpand={true}
        maxWidth={'2500px'}
        Icons={
          <>
            <IconButtons
              action={() => setOpenSettingsModal(true)}
              icon={<BsToggles />}
              text={'Settings'}
            />
            <OptionsModal
              open={openSettingsModal}
              close={() => {
                if (hasChanged(tableMetrics, selectedOptions)) {
                  dispatch(updateTableMetrics(selectedOptions)).then((res) => {
                    if (res) refetch()
                    setOpenSettingsModal(false)
                  })
                } else {
                  setOpenSettingsModal(false)
                }
              }}
              type="settings"
              header="Settings"
              icon={<BsToggles />}
              callToAction={
                <div>Select columns to be displayed in the table</div>
              }
              options={groupedOptions}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
            />
          </>
        }
        Content={
          <>
            {(isFetching || isFetchingWatchlistSymbols) && (
              <div className="min-h-[200px] flex items-center justify-center">
                <ScaleLoader size={5} color={'#218B82'} />
              </div>
            )}
            {!isFetching && !isFetchingWatchlistSymbols && (
              <>
                {!_.isEmpty(tableData) && (
                  <DataTable
                    tableData={tableData}
                    selectedColumns={tableMetrics}
                    columns={metricOptions}
                    totalPages={dataTableTotalPage}
                    currentPage={datatableCurrentPage}
                    setCurrentPage={setDatatableCurrentPage}
                    sortField={sortField}
                    setSortField={setSortField}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    tableRows={tableRows}
                    watchList={watchlistSymbols?.data ?? []}
                  />
                )}
                {_.isEmpty(tableData) && (
                  <Link to={'/stockanalysis'}>
                    <WarningWrapper>
                      <BsBookmarkHeart size={40} />
                      <WarningText>
                        Add stocks to the watch list from the{' '}
                        <span className="font-semibold">Analysis</span> page
                      </WarningText>
                    </WarningWrapper>
                  </Link>
                )}
              </>
            )}
          </>
        }
      />
    </Container>
  )
}

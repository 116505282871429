// External imports
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { IoMdArrowRoundForward, IoMdArrowRoundUp } from 'react-icons/io'
import { useMediaQuery } from 'react-responsive'
import { ScaleLoader } from 'react-spinners'

// Internal imports
import {
  Modal,
  SingleSelectDropdown,
  SingleSelectMetricDropdown,
  IconButtons,
  StyledInput,
  SelectedCriteriaContainer,
} from '..'
import { metricOptions } from '../../data/metricOptions'
import { useUpdateUserScreenerSettingsMutation } from '../../rtk'

// Styling
const MetricContainer = styled.div`
  ${tw`flex rounded w-full flex-col items-start space-y-2`};
`
const TextContainer = styled.span`
  ${tw`text-textMuted text-xs text-center w-full`};
`

const MetricCriteriaSelector = ({ onSubmit, icon }) => {
  const operators = ['Greater than', 'Less than']

  const [selectedMetric, setSelectedMetric] = useState('')
  const [selectedOperator, setSelectedOperator] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [displayedValue, setDisplayedValue] = useState('')
  const [resetSelection, setResetSelection] = useState(false)

  const isSubmitButtonSelectable =
    selectedMetric && selectedOperator && inputValue

  const metricDetails = metricOptions.find(
    (metric) => metric.displayedText === selectedMetric
  )

  const handleSubmit = () => {
    onSubmit(selectedMetric, selectedOperator, inputValue)
    setSelectedMetric('')
    setInputValue('')
    setDisplayedValue('')
    setResetSelection(true)
  }

  useEffect(() => {
    if (resetSelection) {
      setResetSelection(false)
    }
  }, [resetSelection])

  return (
    <MetricContainer>
      <SingleSelectMetricDropdown
        inputPlaceholder="Select metric"
        setSelected={setSelectedMetric}
        resetSelection={resetSelection}
        width="100%"
      />
      <TextContainer>is</TextContainer>
      <SingleSelectDropdown
        inputPlaceholder="Select operator"
        dropDownOptions={operators}
        setSelected={setSelectedOperator}
        resetSelection={resetSelection}
        width="100%"
        sortingMethod='asc'
      />
      <div className="w-full h-[34px] flex flex-row items-center justify-center rounded cursor-auto px-2 space-x-2 text-xs bg-neutralBg border-1 border-primaryBg hover:border-blue-500 mb-4">
        <div className="flex flex-row items-center w-full">
          <span className="text-textMuted min-w-[8px]">
            {metricDetails && metricDetails.prefix && (
              <label>{metricDetails.prefix}</label>
            )}
          </span>
          <StyledInput
              decimalScale={2}
              placeholder={"Type Number"}
              value={displayedValue}
              onChange={(e) => {
                const rawValue = e.target.value.replace(/,/g, '')
                setInputValue(rawValue)
                setDisplayedValue(e.target.value)
              }}
            />
          <span className="text-textMuted min-w-[12px]">
            {metricDetails && metricDetails.suffix && (
              <label>{metricDetails.suffix}</label>
            )}
          </span>
        </div>
      </div>
      <div className="w-full pt-1">
        <IconButtons
          icon={icon}
          isActive={!isSubmitButtonSelectable}
          action={() => (isSubmitButtonSelectable ? handleSubmit() : null)}
          width="w-full"
        />
      </div>
    </MetricContainer>
  )
}

export const FiltersModal = ({
  open,
  close,
  header,
  icon,
  callToAction,
  setSelectedFilters,
  isUpdating,
  selectedFilters,
}) => {
  const convertToHorizontal = useMediaQuery({ maxHeight: '500px' })
  const [filters, setFilters] = useState([])

  useEffect(() => {
    setFilters(selectedFilters)
  }, [selectedFilters])

  const [updateScreenerSettings] = useUpdateUserScreenerSettingsMutation()

  const handleFilterFormSubmit = (name, operator, value) => {
    setFilters([...filters, { name, operator, value }])
  }

  const removeFilter = (index) => {
    const updatedFilters = [...filters]
    updatedFilters.splice(index, 1)
    setFilters(updatedFilters)
  }

  // Need to know the state of the modal before it was open and revert to that state if the modal is closed, so that previous filters can be retained and we don't lose previous filters
  const closeModal = () => {
    close()
    // setFilters(selectedFilters)
    // setSelectedFilters(selectedFilters)
    setSelectedFilters(filters)
    updateScreenerSettings({ screenerFilters: filters })
  }

  const applyFilter = () => {
    close()
    setSelectedFilters(filters)
    updateScreenerSettings({ screenerFilters: filters })
  }

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      headerContent={
        <span className="flex flex-row items-center">
          {icon}
          <h1 className="ml-2">{header}</h1>
        </span>
      }
      width={convertToHorizontal ? '600px' : '450px'}
      maxWidth={convertToHorizontal ? '600px' : '450px'}
      content={
        <div className="w-full ">
          {!isUpdating && (
            <>
              <div className="pt-4 pb-4 text-sm text-textMuted font-semibold border-b-1 mb-2">
                {callToAction}
              </div>

              <div
                className={`flex ${
                  convertToHorizontal
                    ? 'flex-row space-x-2'
                    : 'flex-col space-y-2'
                }`}
              >
                {!convertToHorizontal && (
                  <div className="w-full border-b-1 border-neutral pb-2 h-[190px]">
                    <div className="p-2 rounded bg-neutralBg flex flex-col items-start gap-2 h-full overflow-y-auto">
                      {filters.map((filter, index) => (
                        <SelectedCriteriaContainer
                          key={index}
                          {...filter}
                          index={index}
                          removeFilter={removeFilter}
                        />
                      ))}
                    </div>
                  </div>
                )}
                <div
                  className={`flex items-start ${
                    convertToHorizontal ? 'w-[200px]' : ''
                  }`}
                >
                  <MetricCriteriaSelector
                    onSubmit={handleFilterFormSubmit}
                    icon={
                      convertToHorizontal ? (
                        <IoMdArrowRoundForward />
                      ) : (
                        <IoMdArrowRoundUp />
                      )
                    }
                  />
                </div>
                {convertToHorizontal && (
                  <div className="flex w-[400px]">
                    <div className="w-full border-l-1 border-neutral pl-2 h-full max-h-[188px] ">
                      <div className="p-2 rounded bg-neutralBg flex flex-col items-start gap-2 h-full overflow-y-auto">
                        {filters.map((filter, index) => (
                          <SelectedCriteriaContainer
                            key={index}
                            {...filter}
                            index={index}
                            removeFilter={removeFilter}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {isUpdating && (
            <div className="p-5 h-[200px] flex justify-center items-center">
              <ScaleLoader size={40} color={'#218B82'} />
              <p className="pl-4">Applying filters</p>
            </div>
          )}
        </div>
      }
    />
  )
}

// External imports
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
// import { BsToggles } from "react-icons/bs";
import { MdOutlineFitScreen } from 'react-icons/md'
// import { HiOutlineSquaresPlus } from "react-icons/hi2";
import { useMediaQuery } from 'react-responsive'
import { motion } from 'framer-motion';

// Internal imports
import {
  Graph,
  Modal,
  IconButtons,
  ContentContainer,
  NumericOptionSelector,
  TextOptionSelector,
  SingleSelectDropdown,
} from '../../components/index'
import {
  updateFundamentalTimeHorizon,
  updateFundamentalPeriodType,
} from '../../actions/userPreferences'
import { useGetFundamentalQuery } from '../../rtk'
import { ScaleLoader } from 'react-spinners'
import { useDispatch } from 'react-redux'

// Styling
const motionConfig = (delay = 0) => ({
  initial: { opacity: 0, y: 15 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.8, delay },
});

const MovingComponent = (props) => (
  <motion.div {...motionConfig(props.delay)} {...props}/>
);

const CardContainer = styled.div`
  ${tw`w-full grid items-center justify-center bg-primaryBg text-textBase gap-2 rounded`};
  @media (min-width: 1800px) {
    ${tw`grid-cols-4`};
  }
  @media (max-width: 1799px) {
    ${tw`grid-cols-3`};
  }
  @media (max-width: 1399px) {
    ${tw`grid-cols-2`};
  }
  @media (max-width: 899px) {
    ${tw`grid-cols-1`};
  }
`
const Card = styled.div`
  ${tw`w-[fit] h-[fit] text-xl font-semibold p-2 flex flex-col flex-1 justify-start items-center bg-primaryBg`};
`
const ContainerTopRow = styled.div`
  ${tw`w-full flex flex-row items-center justify-between font-semibold text-base border-b border-neutral pb-2`};
`
const ChartContainer = styled.div`
  ${tw`pt-2 w-full h-[250px]`};
`

// Section for displaying the fundamentals of a stock
export const Fundamentals = ({
  symbol,
  fundamentalTimeHorizon,
  fundamentalPeriodType,
}) => {
  const dispatch = useDispatch()

  const {
    data: fundamentalData,
    isError,
    isFetching,
  } = useGetFundamentalQuery({ symbol, period: fundamentalPeriodType })

  const showDropDownToggles = useMediaQuery({ maxWidth: '850px' })

  const getChartDataAndLabels = (key, innerKey) => {
    if (isFetching || isError) return { data: [], labels: [] }
    const data = []
    const labels = []
    let values = undefined
    if (innerKey === 'sharesOutstanding')
      values = fundamentalData['sharesOutstanding'] ?? fundamentalData[key]
    else values = fundamentalData[key]
    for (const val of values) {
      data.push(val[innerKey])
      labels.push(val.date)
    }
    return {
      data: data.slice(0, fundamentalTimeHorizon).reverse(),
      labels: labels.slice(0, fundamentalTimeHorizon).reverse(),
    }
  }

  // Content for all fundamental graphs
  const graphContent = [
    {
      statType: 'Revenue',
      chartData: [
        {
          name: 'Revenue',
          ...getChartDataAndLabels('incomeStatement', 'revenue'),
        },
      ],
      chartType: 'bar',
      chartColours: ['#84dcc6'],
      isStacked: false,
      valueFormat: 'currency',
      tickRounding: 1,
      dataRounding: 2,
    },
    {
      statType: 'Expenses',
      chartData: [
        {
          name: 'Cost of Revenue',
          ...getChartDataAndLabels('incomeStatement', 'costOfRevenue'),
        },
        {
          name: 'R&D',
          ...getChartDataAndLabels('incomeStatement', 'researchExpenses'),
        },
        {
          name: 'Admin',
          ...getChartDataAndLabels('incomeStatement', 'generalAdmin'),
        },
        {
          name: 'Marketing',
          ...getChartDataAndLabels('incomeStatement', 'marketing'),
        },
      ],
      chartType: 'bar',
      chartColours: ['#AD2831', '#FF6961', '#FFC0CB', '#FFA07A'],
      isStacked: true,
      valueFormat: 'currency',
      tickRounding: 1,
      dataRounding: 2,
    },
    {
      statType: `Earnings`,
      chartData: [
        {
          name: 'Revenue',
          ...getChartDataAndLabels('incomeStatement', 'revenue'),
        },
        {
          name: 'Gross Profit',
          ...getChartDataAndLabels('incomeStatement', 'grossProfit'),
        },
        {
          name: 'EBITDA',
          ...getChartDataAndLabels('incomeStatement', 'ebitda'),
        },
      ],
      chartType: 'bar',
      chartColours: ['#84dcc6', '#6ec2b8', '#5f9ea0'],
      isStacked: false,
      valueFormat: 'currency',
      tickRounding: 1,
      dataRounding: 2,
    },
    {
      statType: 'Free Cash Flow',
      chartData: [
        { name: 'FCF', ...getChartDataAndLabels('cashFlow', 'freeCashFlow') },
        {
          name: 'Stock Based Comp',
          ...getChartDataAndLabels('cashFlow', 'stockBasedCompensation'),
        },
        {
          name: 'SBC adj FCF',
          ...getChartDataAndLabels(
            'cashFlow',
            'stockBasedCompensationAdjustedFreeCashFlow'
          ),
        },
      ],
      chartType: 'bar',
      chartColours: ['#a7cbd9', '#fa898b', '#809bce'],
      isStacked: false,
      valueFormat: 'currency',
      tickRounding: 1,
      dataRounding: 2,
    },
    {
      statType: 'Cash & Debt',
      chartData: [
        { 
          name: 'Cash', 
          ...getChartDataAndLabels('balanceSheet', 'cash') 
        },
        {
          name: 'Long Term Debt',
          ...getChartDataAndLabels('balanceSheet', 'debt'),
          stack: 'Debt', // Specify the stack for long term debt
        },
        {
          name: 'Capital Lease Obligations',
          ...getChartDataAndLabels('balanceSheet', 'capitalLeaseObligations'),
          stack: 'Debt', // Specify the same stack for capital lease obligations
        },
      ],
      chartType: 'bar',
      chartColours: ['#cdeac0', '#fa898b', '#ffbf87'],
      isStacked: true, // Enable stacking
      valueFormat: 'currency',
      tickRounding: 1,
      dataRounding: 2,
    },
    {
      statType: 'Shares Outstanding',
      chartData: [
        {
          name: 'Shares Outstanding',
          ...getChartDataAndLabels('incomeStatement', 'sharesOutstanding'),
        },
      ],
      chartType: 'bar',
      chartColours: ['#be9ddf'],
      isStacked: false,
      valueFormat: '',
      tickRounding: 1,
      dataRounding: 2,
    },
    {
      statType: 'Dividends',
      chartData: [
        {
          name: 'Dividends',
          ...getChartDataAndLabels('historicDailyDividend', 'dividend'),
        },
      ],
      chartType: 'bar',
      chartColours: ['#aebdce'],
      isStacked: false,
      valueFormat: 'currency',
      tickRounding: 2,
      dataRounding: 2,
    },
    {
      statType: 'Effectiveness Ratios',
      chartData: [
        { name: 'ROIC', ...getChartDataAndLabels('keyMetrics', 'roic') },
        { name: 'ROCE', ...getChartDataAndLabels('financialRatios', 'roce') },
        { name: 'ROA', ...getChartDataAndLabels('financialRatios', 'roa') },
        { name: 'ROE', ...getChartDataAndLabels('keyMetrics', 'roe') },
      ],
      chartType: 'line',
      chartColours: ['#809bce', '#EC8F6A', '#cdeac0', '#be9ddf'],
      isStacked: false,
      valueFormat: 'percentage',
      tickRounding: 0,
      dataRounding: 2,
    },
    {
      statType: 'Margins',
      chartData: [
        {
          name: 'Gross Profit',
          ...getChartDataAndLabels('incomeStatement', 'grossProfitRatio'),
        },
        {
          name: 'EBITDA',
          ...getChartDataAndLabels('incomeStatement', 'ebitdaratio'),
        },
        {
          name: 'Profit',
          ...getChartDataAndLabels('incomeStatement', 'netIncomeRatio'),
        },
      ],
      chartType: 'line',
      chartColours: ['#6ec2b8', '#5f9ea0', '#486989'],
      isStacked: false,
      valueFormat: 'percentage',
      tickRounding: 0,
      dataRounding: 2,
    },
    {
      statType: 'Growth Ratios',
      chartData: [
        {
          name: 'Revenue',
          ...getChartDataAndLabels('financialGrowth', 'revenueGrowth'),
        },
        {
          name: 'Profit',
          ...getChartDataAndLabels('financialGrowth', 'netIncomeGrowth'),
        },
        {
          name: 'FCF',
          ...getChartDataAndLabels('financialGrowth', 'freeCashFlowGrowth'),
        },
      ],
      chartType: 'line',
      chartColours: ['#84dcc6', '#486989', '#A7CBD9'],
      isStacked: false,
      valueFormat: 'percentage',
      tickRounding: 0,
      dataRounding: 2,
    },
    {
      statType: 'Price Ratios',
      chartData: [
        {
          name: 'P/E',
          ...getChartDataAndLabels(
            fundamentalPeriodType === 'TTM' ? 'priceRatio' : 'keyMetrics',
            'peRatio'
          ),
        },
        {
          name: 'P/FCF',
          ...getChartDataAndLabels(
            fundamentalPeriodType === 'TTM' ? 'priceRatio' : 'keyMetrics',
            'pfcfRatio'
          ),
        },
      ],
      chartType: 'line',
      chartColours: ['#FBC687', '#32AFA9'],
      isStacked: false,
      valueFormat: '',
      tickRounding: 0,
      dataRounding: 2,
    },
    {
      statType: 'Per Share Ratios',
      chartData: [
        {
          name: 'EPS',
          ...getChartDataAndLabels(
            fundamentalPeriodType === 'TTM'
              ? 'perShareRatio'
              : 'incomeStatement',
            'eps'
          ),
        },
        {
          name: 'FCFPS',
          ...getChartDataAndLabels(
            fundamentalPeriodType === 'TTM' ? 'perShareRatio' : 'keyMetrics',
            'freeCashFlowPerShare'
          ),
        },
      ],
      chartType: 'line',
      chartColours: ['#D45D79', '#8AC6D1'],
      isStacked: false,
      valueFormat: 'currency',
      tickRounding: 2,
      dataRounding: 2,
    },
  ]

  return (
    <ContentContainer
      Header={'Financials'}
      hasExpand={true}
      maxWidth={'2500px'}
      Toggle={
        !showDropDownToggles && (
          <TextOptionSelector
            options={['TTM', 'Annual', 'Quarterly']}
            selection={fundamentalPeriodType}
            setSelection={updateFundamentalPeriodType}
          />
        )
      }
      Icons={
        <div className="flex flex-row space-x-2 items-center">
          {!showDropDownToggles && (
            <NumericOptionSelector
              name={
                fundamentalPeriodType === 'Quarterly' ? 'Quarters' : 'Years'
              }
              options={[5, 10, 20, 40]}
              selection={fundamentalTimeHorizon}
              setSelection={updateFundamentalTimeHorizon}
            />
          )}
          {showDropDownToggles && (
            <div className="flex flex-row space-x-2 w-[200px]">
              <SingleSelectDropdown
                setSelected={(option) =>
                  dispatch(updateFundamentalPeriodType(option))
                }
                dropDownOptions={['TTM', 'Annual', 'Quarterly']}
                width={'full'}
                useParentInput={true}
                parentInput={fundamentalPeriodType}
              />
              <SingleSelectDropdown
                setSelected={(option) =>
                  dispatch(updateFundamentalTimeHorizon(option))
                }
                suffix={
                  fundamentalPeriodType === 'Quarterly' ? 'Quarters' : 'Years'
                }
                dropDownOptions={[5, 10, 20, 40]}
                width={'fit'}
                useParentInput={true}
                parentInput={fundamentalTimeHorizon}
              />
            </div>
          )}
        </div>
      }
      Content={
        <CardContainer>
          {graphContent.map((card, index) => (
            <MovingComponent key={index} delay={index * 0.1}>
              <StatCard
                key={index}
                {...card}
                isFetchingData={isFetching}
                symbol={symbol}
              />
            </MovingComponent>
          ))}
        </CardContainer>
      }
    />
  )
}

// Card for each graph
const StatCard = ({
  statType,
  chartData,
  chartColours,
  chartType,
  isStacked,
  valueFormat,
  displayLegend,
  tickRounding,
  dataRounding,
  isFetchingData,
  symbol,
}) => {
  const [openModal, setOpenModal] = useState(false)
  // const [openSettingsModal, setOpenSettingsModal] = useState(false);
  // Disable scrolling when modal is open
  useEffect(() => {
    document.body.style.overflow = openModal ? 'hidden' : 'auto'
  }, [openModal])
  // Individual chart component
  const Chart = ({ width, height }) =>
    chartData &&
    chartData[0]?.labels && (
      <ChartContainer style={{ width, height }} className="cursor-auto">
        {!isFetchingData && chartData[0]?.labels.length ? (
          <Graph
            statType={statType}
            chartData={chartData}
            chartType={chartType}
            chartColours={chartColours}
            isStacked={isStacked}
            valueFormat={valueFormat}
            displayLegend={displayLegend}
            tickRounding={tickRounding}
            dataRounding={dataRounding}
          />
        ) : (
          <div className="flex justify-center items-center h-full">
            {!isFetchingData ? (
              <h1 className="text-textMuted text-base font-bold">
                No Data to Display
              </h1>
            ) : (
              <ScaleLoader size={40} color={'#218B82'} />
            )}
          </div>
        )}
      </ChartContainer>
    )

  // const groupedOptions = chartOptions
  //   .filter((option) => option.category !== 'Price & valuation')
  //   .reduce((result, option) => {
  //     result[option.category] = result[option.category] || []
  //     result[option.category].push(option)
  //     return result
  //   }, {})

  return (
    <>
      <Card className="border-1 border-neutral">
        <ContainerTopRow>
          {statType}
          <div className="flex flex-row space-x-2">
            <IconButtons
              action={() => setOpenModal(true)}
              icon={<MdOutlineFitScreen />}
              text={'Expand'}
            />
          </div>
        </ContainerTopRow>
        <ChartContainer
          style={{ width: '98%', height: 235 }}
          className="cursor-auto"
        >
          {!isFetchingData && chartData[0]?.data.length ? (
            <Graph
              statType={statType}
              chartData={chartData}
              chartType={chartType}
              chartColours={chartColours}
              isStacked={isStacked}
              valueFormat={valueFormat}
              displayLegend={displayLegend}
              tickRounding={tickRounding}
              dataRounding={dataRounding}
            />
          ) : (
            <div className="flex justify-center items-center h-full">
              {!isFetchingData ? (
                <h1 className="text-textMuted text-base font-bold">
                  No data to display
                </h1>
              ) : (
                <ScaleLoader size={40} color={'#218B82'} />
              )}
            </div>
          )}
        </ChartContainer>
      </Card>
      <Modal
        open={openModal}
        closeModal={() => setOpenModal(false)}
        headerContent={
          <div className="text-lg flex items-center">
            {symbol} - {statType}
          </div>
        }
        content={<Chart height="min(60vw, calc(45vh - 60px))" />}
        footerContent='InvestmentHorizon.io'
        maxWidth={'1500px'}
        maxHeight="50vh"
      />
    </>
  )
}

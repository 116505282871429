// External imports
import React from 'react'

// Internal imports
import { Modal, SettingsTable } from '..'
import { metricOptions } from '../../data/metricOptions'
import { getCategoryIcon } from '../../utils'
// import { ScaleLoader } from 'react-spinners'

function CategoryItem({
  type,
  category,
  settingsOptions,
  selectedOptions,
  setSelectedOptions,
}) {
  const displayedText =
    category === 'metric'
      ? 'Metric'
      : metricOptions.find((option) => option.field === category)
          ?.displayedText || category

  return (
    <div
      key={category}
      className=" border-neutral rounded flex flex-col px-2 pt-2 overflow-hidden bg-neutralBg"
    >
      <div className="flex flex-row items-center justify-between font-semibold cursor-pointer border-b-1 border-neutral pb-2">
        <div className="flex flex-row items-center">
          {getCategoryIcon(category)}
          {displayedText}
        </div>
      </div>
      <SettingsTable
        settingsOptions={settingsOptions}
        selectedSettings={selectedOptions}
        setSelectedSettings={setSelectedOptions}
      />
    </div>
  )
}

export const OptionsModal = ({
  open,
  close,
  type,
  header,
  icon,
  callToAction,
  options,
  selectedOptions,
  setSelectedOptions,
  // isUpdating,
}) => {
  return (
    <Modal
      open={open}
      closeModal={close}
      headerContent={
        <span className="flex flex-row items-center">
          {icon}
          <h1 className="ml-2">{header}</h1>
        </span>
      }
      width="350px"
      maxHeight="95vh"
      content={
        <div className="w-full h-fit">
          {/* {!isUpdating && ( */}
          <div className="w-full h-fit">
            <div className="pt-4 pb-4 text-sm text-textMuted font-semibold ">
              {/* {callToAction} */}
              Select columns to be displayed in the table
            </div>

            <div className="flex flex-row items-start w-full border-t-1 pt-2">
              <div
                className={`w-full flex flex-col rounded pr-2 overflow-y-auto max-h-[500px] h-[calc(100vh_-_160px)]`}
              >
                <div className="grid grid-cols-1 grid-rows-10 gap-2">
                  {Object.entries(options).map(
                    ([category, settingsOptions]) => (
                      <CategoryItem
                        key={category}
                        type={type}
                        category={category}
                        settingsOptions={settingsOptions}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* )} */}
          {/* {isUpdating && (
            <div className="p-5 h-[200px] flex justify-center items-center">
              <ScaleLoader size={40} color={'#218B82'} />
              <p className="pl-4">Updating table columns</p>
            </div>
          )} */}
        </div>
      }
    />
  )
}

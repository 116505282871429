// External imports
import React, { useState, useEffect, useTransition } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useMediaQuery } from 'react-responsive'
import { IoClose, IoSearch } from 'react-icons/io5'

// Internal imports
import { ContentContainer, SingleSelectDropdown } from '../../components/index'
import { ScaleLoader } from 'react-spinners'
import {
  useFetchEarningCallsDetailsQuery,
  useFetchEarningCallsQuery,
} from '../../rtk'
import Highlighter from 'react-highlight-words'

// Styling
const SpeechBubble = styled.div`
  ${tw`w-[fit-content] rounded-lg text-justify text-textMuted leading-[18px] tracking-wider`};
`
const Avatar = styled.div`
  ${tw`text-textButtonActive font-black rounded-md h-[25px] min-h-[25px] min-w-[40px] flex items-center justify-center`};
`
const SpeakerInitials = styled.div`
  ${tw`font-black`}
`
const ErrorText = styled.div`
  ${tw`w-full h-full flex items-center justify-center text-textMuted text-base font-bold`}
`
const SearchContainer = styled.div`
  ${tw`flex flex-row max-w-[200px] min-w-[32px] h-[32px] space-x-2 items-center rounded py-1 px-2 bg-neutralBg`}
`
const SearchInput = styled.input`
  ${tw`w-full max-w-[80px] text-xs bg-neutralBg outline-none`}
`

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const EarningsCalls = ({ symbol }) => {
  const isMobile = useMediaQuery({ maxWidth: '500px' })
  const [yearQuartersMap, setYearQuartersMap] = useState(new Map())
  const [selectedYear, setSelectedYear] = useState('')
  const [selectedQuarter, setSelectedQuarter] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDeBouncedSearchTerm] = useState('')
  const [showSearchInput, setShowSearchInput] = useState(false)
  const [searchCount, setSearchCount] = useState(0)
  const [isPending, startTransition] = useTransition()

  const { data: earningCallsData, isLoading: isEarningCallsLoading } =
    useFetchEarningCallsQuery(symbol)
  const { data: earningCallDetails, isFetching: isEarningCallDetailsFetching } =
    useFetchEarningCallsDetailsQuery({
      symbol,
      year: selectedYear,
      quarter: selectedQuarter,
    })

  useEffect(() => {
    if (earningCallsData?.data) {
      const parsed = JSON.parse(earningCallsData.data)
      const hashMap = new Map(parsed.map(([key, arr]) => [key, new Set(arr)]))
      const maxYear = Math.max(...hashMap.keys())
      if (maxYear > 0) {
        const maxQuarter = Math.max(...Array.from(hashMap.get(maxYear).keys()))
        setYearQuartersMap(hashMap)
        setSelectedYear(maxYear)
        setSelectedQuarter(maxQuarter)
      }
    }
  }, [earningCallsData])

  useEffect(() => {
    const id = setTimeout(() => {
      startTransition(() => {
        setDeBouncedSearchTerm(searchTerm)
      })
    }, 150)

    return () => clearTimeout(id)
  }, [searchTerm])

  useEffect(() => {
    if (earningCallDetails?.data && debouncedSearchTerm) {
      const term = escapeRegExp(debouncedSearchTerm)
      setSearchCount(
        (earningCallDetails.data.content.match(new RegExp(term, 'gi')) || [])
          .length
      )
    }
  }, [debouncedSearchTerm, earningCallDetails])

  const handleYearChange = (year) => {
    setSelectedYear(year)
    const quarters = Array.from(yearQuartersMap.get(year) ?? [])
    const mostRecentQuarter = Math.max(...quarters)
    setSelectedQuarter(mostRecentQuarter)
  }

  const handleQuarterChange = (quarter) => {
    setSelectedQuarter(quarter)
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const handleSearchButtonClick = () => {
    setShowSearchInput(!showSearchInput)
  }

  const handleClearSearch = () => {
    setSearchTerm('')
  }

  const renderContent = (content) => {
    const speakerColors = new Map()
    const colors = [
      '#809bce',
      '#84dcc6',
      '#5f9ea0',
      '#3d6f85',
      '#bb444c',
      '#FF6961',
      '#FFC0CB',
      '#be9ddf',
      '#aebdce',
      '#77889b',
      '#cdeac0',
      '#32AFA9',
      '#526ab6',
      '#a66ab3',
      '#51829B',
      '#638889',
      '#576F72',
      '#7882A4',
      '#FFC6AC',
      '#FFE6E6',
    ]
    let colorIndex = 0
    const paragraphs = content
      .replace(/\[Operator Instructions\]/g, '')
      .split('\n')
      .map((p) => p.trim())
      .filter((p) => p.length > 0)
    const speakersData = []
    let currentSpeaker = null

    paragraphs.forEach((paragraph) => {
      const match = paragraph.match(/^([A-Za-z ]+):(.*)$/)
      if (match) {
        currentSpeaker = match[1].trim()
        speakersData.push({ speaker: currentSpeaker, speech: match[2].trim() })
      } else if (currentSpeaker) {
        speakersData[speakersData.length - 1].speech += `\n${paragraph}`
      }
    })

    return (
      <div className="w-full flex flex-col space-y-2 text-xs items-end pr-1">
        {speakersData.map((item, index) => {
          const { speaker, speech } = item
          const paragraphs = speech
            .split('\n')
            .map((p) => p.trim())
            .filter((p) => p.length > 0)
          const initials = speaker
            .split('-')[0]
            .split(' ')
            .map((word) => word[0])
            .join('')
            .slice(0, 3)
          const colorClass =
            speakerColors.get(speaker) ||
            (() => {
              const color = colors[colorIndex++]
              speakerColors.set(speaker, color)
              colorIndex %= colors.length
              return color
            })()
          const borderClass =
            index > 0 ? 'border-t-1 border-neutralBg pt-2' : ''

          return (
            <div
              className={`w-full flex flex-col space-y-1.5 justify-start ${borderClass}`}
              key={index}
            >
              {paragraphs.length > 0 && (
                <>
                  <div className="flex flex-row space-x-2 items-center">
                    <Avatar style={{ backgroundColor: colorClass }}>
                      {initials}
                    </Avatar>
                    <SpeakerInitials>{speaker}</SpeakerInitials>
                  </div>
                  {paragraphs.map((para, paraIndex) => (
                    <SpeechBubble key={paraIndex}>
                      {!isPending && (
                        <Highlighter
                          highlightClassName="bg-[#3b82f680] rounded-sm"
                          searchWords={[debouncedSearchTerm]}
                          autoEscape={true}
                          textToHighlight={para}
                        />
                      )}
                      {isPending && (
                        <Highlighter
                          highlightClassName="bg-[#3b82f680] rounded-sm"
                          searchWords={[]}
                          autoEscape={true}
                          textToHighlight={para}
                        />
                      )}
                    </SpeechBubble>
                  ))}
                </>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <>
      {(isEarningCallDetailsFetching ||
        isEarningCallsLoading ||
        earningCallDetails?.data) && (
        <ContentContainer
          Header={'Transcripts'}
          hasExpand={true}
          maxWidth={'2500px'}
          Icons={
            <div className="flex flex-row space-x-2 justify-end items-center">
              {!isMobile && (
                <SearchContainer>
                  <div
                    className="w-[14px] h-[14px] text-textMuted hover:text-textBase cursor-pointer"
                    onClick={handleSearchButtonClick}
                  >
                    <IoSearch className="w-[14px] h-[14px]" />
                  </div>
                  {showSearchInput && (
                    <>
                      <SearchInput
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search..."
                      />
                      <div className="w-[34px] h-[14px] text-textMuted flex justify-center items-center">
                        {searchTerm !== '' && (
                          <span className="w-[34px] h-[14px] text-xs font-thin text-right">
                            {searchCount.toLocaleString()}
                          </span>
                        )}
                      </div>
                      <div
                        className="w-[14px] h-[14px] text-textMuted hover:text-textBase cursor-pointer"
                        onClick={handleClearSearch}
                      >
                        {searchTerm !== '' && (
                          <IoClose className="w-[14px] h-[14px]" />
                        )}
                      </div>
                    </>
                  )}
                </SearchContainer>
              )}
              <SingleSelectDropdown
                dropDownOptions={Array.from(yearQuartersMap.keys() ?? [])}
                setSelected={handleYearChange}
                width={'70px'}
                useParentInput={true}
                parentInput={selectedYear}
              />
              <SingleSelectDropdown
                dropDownOptions={Array.from(
                  yearQuartersMap.get(selectedYear)?.keys() ?? []
                )}
                setSelected={handleQuarterChange}
                width={'50px'}
                prefix="Q"
                useParentInput={true}
                parentInput={selectedQuarter}
              />
            </div>
          }
          Content={
            <div
              className={`w-full min-h-[150px] h-[calc(100vh_-_150px)] max-h-[700px] overflow-y-auto p-1`}
            >
              {(isEarningCallDetailsFetching || isEarningCallsLoading) && (
                <div className="h-full flex justify-center items-center">
                  <ScaleLoader size={40} color={'#218B82'} />
                </div>
              )}
              {!isEarningCallDetailsFetching &&
                !isEarningCallsLoading &&
                (earningCallDetails?.data ? (
                  renderContent(earningCallDetails.data.content)
                ) : (
                  <ErrorText>No Data to Display</ErrorText>
                ))}
            </div>
          }
        />
      )}
    </>
  )
}

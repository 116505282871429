const COMPANY_NAME = 'Investment Horizon';
export const COMPANY_WEBSITE_SHORT = 'Horizon';
const COMPANY_WEBSITE = 'www.investmenthorizon.io';
const POLICY_EFFECTIVE_DATE = '22 April 2024';

// Your Stock Market Companion. Investing intelligence at your fingertips

export const landingPage = {
  overviewLine1:
  `Gain insights, track trends, and optimize your investments to grow your wealth`,
  overviewLine2:
  `Join us today and unlock the potential of your financial future`,
  callToAction:
  `Achieve your investing goals`,
  searchTagLine:
  `Global Reach at Your Fingertips`,
  searchOptionality:
  `Search over 28,000 stocks`,
  searchParagraphText:
  `Effortlessly search and analyze stocks around the globe. Explore international markets effortlessly and discover investment opportunities beyond borders`,
  analysisTagLine:
  `Analytics in one streamlined view`,
  analysisParagraphText:
  `Horizon's analysis tool streamlines and simplifies over 30 years of complex financial and price data into clear, easy to understand insights, within seconds`,
  watchListTagLine:
  `Stay Ahead, Stay Informed`,
  watchListParagraphText:
  `Track stocks while you sleep. Personalise your advanced watchlist to focus on what is important to you`,
  notificationsTagLine:
  `Stay Ahead, Stay Informed`,
  notificationsParagraphText:
  `Track stocks while you sleep. Ensure you're always ahead of the curve, with instant updates on market trends, stock movements, and personalized insights tailored to your portfolio`,
  screenerTagLine:
  `Discover Hidden Gems`,
  screenerParagraphText:
  `Identify undervalued stocks, emerging trends, and disruptive industries for growth opportunities with our advanced screener`,
  calendarTagLine:
  `Stay Ahead, Stay Informed`,
  calendarParagraphText:
  `Track stocks while you sleep. Personalise your advanced watchlist to focus on what is important to you`,
  analysisFeatureText:
  `Our modern analysis empowers you to make informed investment decisions. Uncover hidden trends, evaluate performance metrics, and gain valuable insights into the stocks you're interested in. Whether you're a seasoned investor or just starting out, our user-friendly interface and expertly curated data will guide you towards smarter investment decisions. Delve into the essentials - from fundamentals and news to price and valuation - for the stocks that capture your attention`,
  watchListFeatureText:
  `Stay ahead of the market with our intuitive stock tracking and watchlist feature. Create a personalized watchlist of your favorite stocks and keep a close eye on their performance. Get updates on price changes, news, and events that could impact your investments. Our platform ensures you never miss a beat, allowing you to make timely decisions based on the latest information. Manage and monitor your investments effectively by tracking your watchlist in one convenient location`,
  screenerFeatureText:
  `Unlock the potential of stock screening and discover investment opportunities like never before. Tailor your search criteria to identify stocks that align with your strategy. Our advanced screening tools enable you to filter stocks based on key metrics such as price, market cap, P/E ratio, and more. Whether you're seeking growth, value, or dividend stocks, our screening feature empowers you to fine-tune your investment choices. Save time and effort by focusing on stocks that match your criteria and enhance your portfolio's potential and find your next quality investment`,
  placeholder1:
  `Find fast growing stocks`,
  placeholder2:
  `Optimize Your Portfolio: Identify opportunities, manage risks, and maximize returns with our comprehensive portfolio management tools`,
  placeholder2:
  `Discover Hidden Gems: Explore undervalued stocks, emerging trends, and disruptive industries for growth opportunities`,
  placeholder2:
  `Join a Community of Investors: Connect with like-minded investors, share ideas, and learn from industry experts`,
  placeholder2:
  `Your personal analyst`,
  feature1:
  `Packed with Features: Including deep analysis on 16,000+ stocks, customizable search, watchlists, email alerts and much more`,
  feature2:
  `Excellent Support: Free U.S. based live support to help you trade confidently and realize consistent results`,
  investorQuote1:
  `"Be fearful when others are greedy and greedy when others are fearful." - Warren Buffett`,
  investorQuote2:
  `"Know what you own, and know why you own it." - Peter Lynch`,
  investorQuote3:
  `"The first rule of compounding: Never interrupt it unnecessarily." - Charlie Munger`,
}

export const quotes = [
  {
    text: '"Know what you own, and know why you own it."',
    author: 'Peter Lynch',
  },
  {
    text: '"Successful investing is about managing risk, not avoiding it."',
    author: 'Benjamin Graham',
  },
  {
    text: '"Be fearful when others are greedy and greedy when others are fearful."',
    author: 'Warren Buffett',
  },
];

export const registrationStages = [
  {number: '1', title: 'Create an Account', description: 'Create an account to get started', icon: 'user'},
  {number: '2', title: 'Choose a plan', description: 'Choose a plan that works for you', icon: 'package'},
  {number: '3', title: 'Payment information', description: 'Enter your payment information', icon: 'payment'},
  {number: '4', title: 'Welcome', description: 'Welcome to the family!', icon: 'welcome'},
]

export const paymentPlans = [
  // {
  //   title: 'STARTER', 
  //   type:'starter',
  //   features: ['Historical Performance Analysis', 'Customizable Reports', 'Watchlists', 'News and Insights', 'Stock Screener', 'Searches: 5 per month'], 
  //   monthlyPrice: 'Free', 
  //   monthlyPaymentPeriod: 'No expiration date',
  //   annualPrice: 'Free', 
  //   annualPaymentPeriod: 'No expiration date',
  //   ctaText: 'Downgrade'
  // },
  {
    title: 'Pro',
    type:'premium', 
    // features: ['Historical Performance Analysis', 'Customizable Reports', 'Watchlists', 'News and Insights', 'Stock Screener', 'Searches: unlimited', 'Notifications', 'Priority Customer Support'], 
    features: ['1 month free trial', '30+ years of historic data', 'Unlimited searches', 'Stock screener', 'Custom watchlist', 'Earnings / dividends calendar'],
    monthlyPrice: '6', 
    monthlyPaymentPeriod: 'Billed monthly in advance',
    annualPrice: '5', 
    annualPaymentPeriod: 'Billed yearly in advance',
    ctaText: 'Upgrade'
  },
]

export const footerText = {
  Paragraph1: 
  `Please read the following disclaimer carefully before using the services provided by ${COMPANY_NAME}. ${COMPANY_NAME} is not operated by a broker, dealer, or registered investment/financial adviser. Any information posted on ${COMPANY_WEBSITE} is for general informational purposes only and is not intended to be, nor does it constitute, investment advice or a recommendation to purchase or sell any security or investment. The information on this site, and in its related newsletters, is not tailored to your individual circumstances or needs and has been prepared without considering your objectives, financial situation, or needs. Any advice or information contained on this website should not be relied upon without appropriate financial, taxation, and legal advice. You should always seek the assistance of a professional for financial or investment advice.`,
  Paragraph2: 
  `${COMPANY_NAME} does not guarantee the completeness, accuracy, timeliness, or reliability of any content or other material published or available on ${COMPANY_WEBSITE}, or relating to the use of, or inability to use, ${COMPANY_WEBSITE} or any content. Certain information and data provided in connection with our services may be delayed as specified by financial exchanges or Information Providers. ${COMPANY_NAME} and the Information Providers furnish the information available in connection with these services without responsibility for accuracy, and you agree that errors or omissions contained in such information shall not be made the basis for any claim, demand or cause of action against ${COMPANY_NAME} or any Information Provider. In no event shall ${COMPANY_NAME} be liable to any member, guest, or third party for any damages of any kind arising out of the use of any content or other material published or available on ${COMPANY_WEBSITE}, including, without limitation, any investment losses, lost profits, lost opportunity, special, incidental, indirect, consequential or punitive damages. Past performance is not indicative of future results and should not be relied upon.`,
  CopyRightText: `© 2024 ${COMPANY_NAME} Limited. All Rights Reserved.`,
};

export const aboutText = [
  {
    header: `What is ${COMPANY_WEBSITE_SHORT}`,
    body: `${COMPANY_WEBSITE_SHORT} is your premier destination for analyzing existing and potential investments. Effortlessly screen for opportunities that resonate with your investment strategy, conduct in-depth analyses, and conveniently track them in your personalized watchlist.`,
  },
  {
    header: `Our team`,
    body: `We are a dynamic team of seasoned investors and tech experts, bringing together years of diverse expertise to craft the ultimate financial analysis platform.`,
  },
  {
    header: `What ${COMPANY_NAME} Isn't`,
    body: `We are not a broker, dealer or registered investment/financial adviser. We are not a financial institution, bank, registered securities exchange, registered clearing agency or registered market maker.`,
  },
  {
    header: 'Our Mission',
    body: `We committed to building a finance platform that seamlessly integrates finance and technology, empowering our community with invaluable market insights. Our goal is to arm you with the tools and information necessary to navigate the complex world of investing and to make informed investment decisions with your hard-earned capital. Dedicated to delivering an exceptional user experience, we encourage you to connect with us through our support channel for any questions or suggestions you may have. Your feedback is invaluable as we continue to enhance our platform.`,
  },
];

export const supportText = [
  {
    header: 'Technical Issues',
    body: `If you're experiencing technical issues while using our website, please let us know. We'll do our best to help you troubleshoot the problem and find a solution. Be sure to provide as much information as possible, including the type of device and browser you're using, as well as any error messages you've received.`,
  },
  {
    header: 'General Questions',
    body: `If you have any general questions about our website, please let us know. We'll do our best to answer your questions.`,
  },
  {
    header: 'Suggestions',
    body: `If you have any suggestions for how we can improve our website, please let us know. We'll do our best to implement your suggestions.`,
  },
];

export const privacyPolicyText = [
  // {
  //   header: 'Paragraph1',
  //   body: `We are committed to protecting your privacy. We will only use the information that we collect about you lawfully (in accordance with the Data Protection Act 1998 and the EU Privacy and Communications Directive). This includes information submitted voluntarily by you through a form or gathered automatically as you visit this website.`,
  // },
  // {
  //   header: 'Paragraph2',
  //   body: `Some cookies used on this website are essential in order to enable you to move around the website and use its features, such as accessing secure areas of the website. Without these cookies, services you have asked for, like shopping baskets or e-billing, cannot be provided.`,
  // },
  {
    header: 'Introduction',
    body: `This privacy policy (the "Policy") describes how ${COMPANY_NAME} collects, uses, and discloses your personal information when you visit our website (the "Site") or use our services.`,
  },
  //Update
  {
    header: 'Information We Collect',
    body: `We collect information from you when you register an account on our site, subscribe to our newsletter, respond to a survey, fill out a form, or use our services. When registering on our site, you may be asked to enter your: name, e-mail address, date of birth, phone number and/or credit card information.`, //You may, however, visit our site anonymously.
  },
  //Update
  {
    header: 'How We Use Your Personal Information',
    body: 
    (
      <>
      Any of the information we collect from you may be used in one of the following ways:
        <ul className="list-disc ml-4">
          <li>Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested;</li>
          <li>To process transactions;</li>
          <li>To personalize your experience (your information helps us to better respond to your individual needs);</li>
          <li>To improve our website (we continually strive to improve our website offerings based on the information and feedback we receive from you);</li>
          <li>To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs);</li>
          <li>To comply with our legal obligations;</li>
          <li>To administer contests, promotions, surveys or other site features;</li>
          <li>To send periodic emails (the email address you provide for the registration process, may be used to send you information and updates pertaining to your registration, in addition to receiving occasional updates or service information, etc.).</li>
        </ul>
      </>
    ),
    
    // `Any of the information we collect from you may be used in one of the following ways: To personalize your experience (your information helps us to better respond to your individual needs) To improve our website (we continually strive to improve our website offerings based on the information and feedback we receive from you) To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs) To process transactions Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested. To comply with our legal obligations. To administer a contest, promotion, survey or other site feature To send periodic emails The email address you provide for the registration process, may be used to send you information and updates pertaining to your registration, in addition to receiving occasional updates or service information, etc.`,
  },
  //How does this work for stripe subscriptions?
  {
    header: 'How We Protect Your Personal Information',
    body: `We implement a variety of security measures to maintain the safety of your personal information when you register or access your personal information. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our payment gateway provider's database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be stored on our servers.`,
  },
  //Update
  {
    header: 'Do We Use Cookies',
    body: `Yes (Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service provider's systems to recognize your browser and capture and remember certain information). We use cookies to help us understand and save your preferences for future visits, keep track of advertisements and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future. We may contract with third-party service providers to assist us in better understanding our site visitors. These service providers are not permitted to use the information collected on our behalf except to help us conduct and improve our business.`,
  },
  {
    header: 'Do We Disclose Any Information To Outside Parties',
    body: `We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.`,
  },
  {
    header: 'Third-party Links',
    body: `Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.`,
  },
  {
    header: 'Your Rights In Relation To Your Data',
    body: `You have the right to request access to your data that we store and the rights to either rectify or erase your personal data. You have the right to seek restrictions on the processing of your data. You have the right to object to the processing of your data and the right to the portability of your data. To the extent that you provided consent to our processing of your personal data, you have the right to withdraw that consent at any time, without affecting the lawfulness of processing based upon consent that occurred prior to your withdrawal of consent. We require only the information that is reasonably required to enter into a contract with you. We will not require you to provide consent for any unnecessary processing as a condition of entering into a contract with us.`,
  },
  {
    header: 'Variation',
    body: `We may revise this policy from time-to-time. The revised privacy policy will apply to the use of this website from the date of the publication of the revised policy on this website. Please check this page regularly to ensure you are familiar with the current version.`,
  },
  {
    header: 'Contact Us',
    body: `If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us through support.`,
  },
  {
    header: 'Effective Date',
    body: `This Privacy Policy is effective as of ${POLICY_EFFECTIVE_DATE}.`,
  },
];

export const termsOfServiceText = [
  {
    header: 'Introduction',
    body: `These terms and conditions govern your use of this website; by using this website, you accept these terms and conditions in full and without reservation. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.`,
  },
  // {
  //   header: 'Intellectual Property Rights',
  //   body: `Unless otherwise stated, we or our licensors own the intellectual property rights in the website and material on the website. Subject to the license below, all these intellectual property rights are reserved.`,
  // },
  {
    header: 'Use of the Website',
    body: `You may view, download for caching purposes only, and print pages from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.`,
  },
  //Update
  {
    header: 'You Must Not',
    body: 
    (
      <>
        <ul className="list-disc ml-4">
          <li>Republish material from this website (including republication on another website);</li>
          <li>Sell, rent or sub-license material from the website;</li>
          <li>Show any material from the website in public;</li>
          <li>Reproduce, duplicate, copy or otherwise exploit material on this website for a commercial purpose;</li>
          <li>Edit or otherwise modify any material on the website;</li>
          <li>Redistribute material from this website (except for content specifically and expressly made available for redistribution).</li>
        </ul>
      </>
    ),
  },
  {
    header: 'Acceptable Use',
    body: `You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity. You must not use this website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software. You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this website without our express written consent. You must not use this website to transmit or send unsolicited commercial communications. You must not use this website for any purposes related to marketing without our express written consent.`,
  },
  {
    header: 'Restricted Access',
    body: `Access to certain areas of this website is restricted. We reserve the right to restrict or terminate access to other areas of this website, or indeed this entire website, at our discretion. If we provide you with a user ID and password to enable you to access restricted areas of this website or other content or services, you must ensure that the user ID and password are kept confidential. We may disable your user ID and password in our sole discretion without notice or explanation.`,
  },
  {
    header: 'User Content',
    body: `In these terms and conditions, "your user content" means material (including without limitation text, images, audio material, video material and audio-visual material) that you submit to this website, for whatever purpose. You grant to us a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate and distribute your user content in any existing or future media. You also grant to us the right to sub-license these rights, and the right to bring an action for infringement of these rights. Your user content must not be illegal or unlawful, must not infringe any third party's legal rights, and must not be capable of giving rise to legal action whether against you or us or a third party (in each case under any applicable law). You must not submit any user content to the website that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint. We reserve the right to edit or remove any material submitted to this website, or stored on our servers, or hosted or published upon this website. Notwithstanding our rights under these terms and conditions in relation to user content, we do not undertake to monitor the submission of such content to, or the publication of such content on, this website.`,
  },
  {
    header: 'Warranties',
    body: `This website is provided "as is" without any representations or warranties, express or implied. We make no representations or warranties in relation to this website or the information and materials provided on this website. Without prejudice to the generality of the foregoing paragraph, we do not warrant that: this website will be constantly available, or available at all; or the information on this website is complete, true, accurate or non-misleading. Nothing on this website constitutes, or is meant to constitute, advice of any kind. If you require advice in relation to any legal or financial matter you should consult an appropriate professional.`,
  },
  {
    header: 'Limitations of Liability',
    body: `To the maximum extent permitted by law, we will not be liable to you for any direct loss; for any indirect, special or consequential loss or for any business losses, loss of revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation or goodwill or other intagible losses, or loss or corruption of information or data in relation to the use or inability to use this website, any content on this website, or otherwise in connection with this website: These limitations of liability apply even if we have been expressly advised of the potential loss.`,
  },
  {
    header: 'Exceptions',
    body: `Nothing in this website disclaimer will exclude or limit any warranty implied by law that it would be unlawful to exclude or limit; and nothing in this website disclaimer will exclude or limit our liability in respect of any: death or personal injury caused by our negligence; fraud or fraudulent misrepresentation on the part of us; or matter which it would be illegal or unlawful for us to exclude or limit, or to attempt or purport to exclude or limit, its liability.`,
  },
  {
    header: 'Reasonableness',
    body: `By using this website, you agree that the exclusions and limitations of liability set out in this website disclaimer are reasonable. If you do not think they are reasonable, you must not use this website.`,
  },
  {
    header: 'Other Parties',
    body: `You accept that, we have an interest in limiting the personal liability of our officers and employees. You agree that you will not bring any claim personally against our officers or employees in respect of any losses you suffer in connection with the website. Without prejudice to the foregoing paragraph, you agree that the limitations of warranties and liability set out in this website disclaimer will protect our officers, employees, agents, subsidiaries, successors, assigns and sub-contractors as well as us.`,
  },
  {
    header: 'Unenforceable Provisions',
    body: `If any provision of this website disclaimer is, or is found to be, unenforceable under applicable law, that will not affect the enforceability of the other provisions of this website disclaimer.`,
  },
  {
    header: 'Indemnity',
    body: `You hereby indemnify us and undertake to keep us indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by us to a third party in settlement of a claim or dispute on the advice of our legal advisers) incurred or suffered by us arising out of any breach by you of any provision of these terms and conditions, or arising out of any claim that you have breached any provision of these terms and conditions.`,
  },
  {
    header: 'Breaches of These Terms and Conditions',
    body: `Without prejudice to our other rights under these terms and conditions, if you breach these terms and conditions in any way, we may take such action as we deem appropriate to deal with the breach, including suspending your access to the website, prohibiting you from accessing the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website and/or bringing court proceedings against you.`,
  },
  {
    header: 'Assignment',
    body: `We may transfer, sub-contract or otherwise deal with our rights and/or obligations under these terms and conditions without notifying you or obtaining your consent. You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms and conditions.`,
  },
  {
    header: 'Severability',
    body: `If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.`,
  },
  {
    header: 'Entire Agreement',
    body: `These terms and conditions, together with our Privacy Policy, constitute the entire agreement between you and us in relation to your use of this website, and supersede all previous agreements in respect of your use of this website.`,
  },
  // {
  //   header: 'LawAndJurisdiction',
  //   body: `These terms and conditions will be governed by and construed in accordance with the laws of the State of California, and any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of the State of California.`,
  // },
  // {
  //   header: 'RegistrationsAndAuthorisations',
  //   body: `We are registered with the California Secretary of State. You can find the online version of the register at http://www.sos.ca.gov/business-programs/business-entities/cbs-search-tips/. Our registration number is 2017-000789999.`,
  // },
    // {
  //   header: 'Disclaimer',
  //   body: `The information provided on this website is for informational purposes only and is not intended to be investment advice. You should always consult with a qualified financial advisor before making any investment decisions.`,
  // },
  // {
  //   header: 'Use of the Website',
  //   body: `You agree to use this website in a manner that is consistent with all applicable laws and regulations. You may not use this website for any illegal or unauthorized purpose. You agree not to upload, post, or transmit any content that is unlawful, threatening, abusive, defamatory, obscene, vulgar, pornographic, profane, indecent, or otherwise objectionable. You agree not to upload, post, or transmit any content that may infringe on the rights of others, including but not limited to copyright, trademark, or privacy rights. You agree not to upload, post, or transmit any unsolicited or unauthorized advertising, promotional materials, or other forms of solicitation.`,
  // },
  {
    header: 'Variation',
    body: `We may revise these terms and conditions from time-to-time. The revised terms and conditions will apply to the use of this website from the date of the publication of the revised terms and conditions on this website. Please check this page regularly to ensure you are familiar with the current version.`,
  },
  {
    header: 'Contact Us',
    body: `If you have any questions about these Terms and Conditions, the practices of this site, or your dealings with this site, please contact us through support.`,
  },
  {
    header: 'Effective Date',
    body: `These Terms and Conditions are effective as of ${POLICY_EFFECTIVE_DATE}.`,
  },
];

export const DiscountedCashFlowText = {
  CurrentCashFlow: `The net cash flow generated by the investment or business currently. It represents the cash inflows and outflows during the present period and serves as the starting point for the DCF analysis.`,
  ExpectedGrowthRate: `The estimated annual growth rate of cash flows for the initial years of the projection period. This rate represents the expected percentage increase in cash flows from year to year, taking into account factors such as industry trends, market conditions, and company performance.`,
  Years: `The number of years into the future for which you want to calculate the cash flow and valuation. DCF analysis typically projects cash flows over a certain time horizon, and the number of years helps determine the scope of the analysis.`,
  DiscountRate: `Also known as the required rate of return or the cost of capital, the discount rate represents the rate of return an investor expects to earn for the investment's risk level. It is used to discount future cash flows to their present value. The discount rate accounts for the time value of money and reflects the opportunity cost of investing in one asset versus another with similar risk.`,
  TerminalGrowthRate: `The estimated long-term growth rate assumed for cash flows beyond the projection period. This growth rate is typically used to estimate the perpetuity value or terminal value of an investment. It represents the sustainable growth rate that a company can achieve in the long run.`,
  NetPresentValue: `The difference between the sum of the present values of future cash flows and the initial investment. It represents the net value generated by the investment after accounting for the time value of money. A positive net present value indicates that the investment is expected to generate a return higher than the required rate of return, while a negative net present value suggests the opposite.`,
  CurrentValuation: `The current value of the investment or business, typically represented by its market capitalization, enterprise value, or a similar valuation metric. It reflects the market's perception of the investment's worth based on factors such as financial performance, growth potential, and industry conditions. In the DCF analysis, the current valuation serves as a reference point for comparing against the calculated valuation to assess the investment's potential undervaluation or overvaluation.`,
};

export const MetricText = {
  PERatio: ``,
  PBRatio: ``,
  FCFYeild: ``,
  ROIC: ``,
  ROCE: ``,
  ROA: ``,
  ROE: ``,
  GrossProfitMargin: ``,
  EBITDAMargin: ``,
  ProfitMargin: ``,
  FreeCashFlowMargin: ``,
  RevenueGrowth: ``,
  EBITDAGrowth: ``,
  ProfitGrowth: ``,
  FreeCashFlowGrowth: ``,
  DividendYield: ``,
  DividendGrowth: ``,
  DividendPayoutRatio: ``,
  CurrentRatio: ``,
  NetDebtToEBITDA: ``,
  DebtGrowth: ``,
};
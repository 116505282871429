// External imports
import styled from 'styled-components'
import tw from 'twin.macro'
import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { PaymentDetailsCard } from '..'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { createSetupIntent } from '../../actions/payment'

// Styling
const CardContainer = styled.div`${tw`w-[fit-content] min-h-[385px] flex flex-col justify-center items-center rounded p-2`};`

export const UserCards = ({ stripePromise, paymentCompleted, cards, updateCompleted }) => {
  const dispatch = useDispatch()
  const [clientSecret, setClientSecret] = useState()
  const options = {
    clientSecret,
    appearance: {
      theme: localStorage.getItem('theme') === 'dark' ? 'night' : 'flat',
    },
  }

  useEffect(() => {
    dispatch(createSetupIntent()).then((res) => {
      if (res) setClientSecret(res)
    })
  }, [cards])

  return (
    <CardContainer>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <PaymentDetailsCard
            buttonText="Save"
            successfulPayment={() => {
              paymentCompleted(true)
            }}
            secondaryButtonText="Cancel"
            secondarySuccessfulPayment={() => {
              paymentCompleted(true)
            }}
            isUpdating={true}
            setupSecret={clientSecret}
            successfulUpdate={() => {
              updateCompleted(true)
            }}
          />
        </Elements>
      )}
    </CardContainer>
  )
}

import { COMPANY_WEBSITE_SHORT } from '../data/text'

/**
 * Incremented down to 1,000, if there are 2,000 users, then it will say over 1,000.
 * If there are 2,001 users, then it will say over 2,000.
 * If there are 35,356 users, then it will say over 35,000.
 * If there is under 1,000 users then it will say "Join NAME today"
 * @param {*} users
 */
export const landingPageTotalUserHelper = (users) => {
  if (users < 1000) return `Trusted by investors worldwide`;
  const rounded = Math.round(users / 1000) * 1000;
  const formattedUsers = rounded.toLocaleString();
  return `Trusted by over ${
    rounded === users ? formattedUsers - 1000 : formattedUsers
  } investors worldwide`;
};
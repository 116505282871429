// External imports
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { BsCheck } from 'react-icons/bs'
import { IoClose } from 'react-icons/io5'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

// Internal imports
import { paymentPlans } from '../../data/text'
import { FormButton } from '../../components'
import {
  cancelSubscription,
  createCustomer,
  createSubscription,
  updateSubscription,
} from '../../actions/payment'
import {
  getSubscriptionDetails,
  updateSubscriptionType,
} from '../../actions/user'

// Styling
const CardContainer = styled.div`
  ${tw`w-[240px] flex flex-col justify-start items-start m-3 p-2 rounded-lg bg-primaryBg border-1 border-textBase shadow-md`};
`
const Button = styled.button`
  ${tw`w-[100px] text-xs font-semibold cursor-pointer rounded p-1.5 flex justify-center border-1 border-primaryBg hover:shadow-md hover:border-blue-500 hover:text-textBase`};
  background-color: ${({ active }) => active ? 'var(--activeBg)' : 'var(--neutralBg)'};
  color: ${({ active }) => active ? 'var(--textButtonActive)' : 'var(--textMuted)'};
  pointer-events: ${({ active }) => (active ? 'none' : 'auto')};
`

export const PaymentPlans = ({ header, goToPayment, setOpenPlanModal }) => {
  const {
    subscriptionType,
    stripeSubscriptionId,
    billingPeriod,
    pricing,
    stripeCustomerId,
    trialBefore,
    customerCards,
  } = useSelector((state) => state.watchListReducer)

  const [periodType, setPeriodType] = useState(() => {
    if (billingPeriod) return billingPeriod
    else return 'monthly'
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = useDispatch()
  const [priceDetails, setPriceDetails] = useState()

  const onToggleClick = () =>
    setPeriodType(periodType === 'yearly' ? 'monthly' : 'yearly')

  const handleSubmit = async (type) => {
    setIsSubmitting(true)
    let priceId =
      periodType === 'monthly'
        ? process.env.REACT_APP_STRIPE_PREMIUM_PLAN_MONTHLY
        : process.env.REACT_APP_STRIPE_PREMIUM_PLAN_ANNUAL
    if (stripeCustomerId && customerCards.id) {
      dispatch(updateSubscription(priceId))
        .then((intent) => {
          dispatch(updateSubscriptionType(type, periodType)).then(() =>
            dispatch(getSubscriptionDetails())
          )
        })
        .catch((err) => console.error(err))
        .finally(() => setIsSubmitting(false))
    } else {
      dispatch(createCustomer())
        .then((customer) => {
          let customerId = customer.customerId
          dispatch(createSubscription({ priceId, customerId })).then(
            (intent) => {
              intent.customerId = customerId
              intent.type = type
              intent.billingPeriod = periodType
              goToPayment(intent)
            }
          )
        })
        .catch((err) => console.error(err))
    }
  }

  useEffect(() => {
    const priceDeets = pricing.find((price) => price.period == periodType)
    setPriceDetails(priceDeets)
  }, [periodType])

  useEffect(() => {
    if (billingPeriod) setPeriodType(billingPeriod)
  }, [billingPeriod])

  // cancel subscription
  const cancelSub = async () => {
    if (stripeSubscriptionId) {
      dispatch(cancelSubscription())
        .then(() => dispatch(getSubscriptionDetails()))
        .then((_) => setOpenPlanModal(false))
    }
  }

  const Card = ({ title, type, features, price, paymentPeriod, cta }) => {
    return (
      <CardContainer>
        <div className="w-full">
          <h1 className="w-full h-[fit-content] flex items-center justify-center text-2xl font-base rounded text-textBase font-semibold text-blue-500 mt-10">
            {title}
          </h1>

          <div className="w-full flex flex-row items-center justify-center">
            <div className="w-full flex flex-col justify-center items-center py-2">
              {priceDetails && (
                <div className="text-4xl font-base font-semibold text-center	">
                  <span>
                    {periodType === 'monthly'
                      ? `$${Number(price)}`
                      : `$${Number(price) * 12}`}
                  </span>
                </div>
              )}
              <span className="text-textMuted text-xs">
                {periodType === 'monthly' ? 'USD per month' : 'USD per year'}
              </span>
              <div className="text-xs text-textMuted pb-1">{paymentPeriod}</div>
            </div>
          </div>
        </div>

        <div className="h-[max-content] w-full flex items-start justify-center pb-10 pt-6">
          <div className="space-y-2 ">
            {features.map((feature, index) => {
              return (
                <div
                  className="w-full flex flex-row space-x-2 items-center"
                  key={index}
                >
                  <BsCheck className="font-semibold text-lg text-textBase" />
                  {/* <IoClose className='font-semibold text-lg text-textMuted'/> */}
                  <div className="h-full text-sm">{feature}</div>
                </div>
              )
            })}
          </div>
        </div>
        {!trialBefore && (
          <FormButton
            text={'Start trial'}
            isLoading={isSubmitting}
            onClick={() => handleSubmit(type)}
          />
        )}
        {trialBefore && subscriptionType != type && (
          <FormButton
            text={cta}
            isLoading={isSubmitting}
            onClick={() => handleSubmit(type)}
          />
        )}
        {trialBefore && subscriptionType == type && (
          <FormButton
            isNegative={true}
            isDisabled={billingPeriod !== periodType}
            text={'Cancel subscription'}
            onClick={() => cancelSub()}
          />
        )}
      </CardContainer>
    )
  }

  return (
    <div className="h-full flex flex-col justify-center items-center">
      <h1 className="text-5xl font-semibold">{header}</h1>
      <div className="w-full h-max-content justify-around flex flex-row pt-2 items-center">
        <div></div>
        <div className="text-[#6ec2b8] font-semibold flex justify-start text-xs ml-2">
          Save 16%
        </div>
      </div>
      <div className="w-full h-max-content justify-center space-x-2 flex flex-row items-center">
        <Button
          active={periodType !== 'yearly'}
          onClick={() => setPeriodType('monthly')}
        >
          Monthly
        </Button>
        <Button
          active={periodType === 'yearly'}
          onClick={() => setPeriodType('yearly')}
        >
          Annual
        </Button>
      </div>
      <div className="w-full flex flex-row justify-center items-center space-x-10">
        {paymentPlans.map((card, index) => {
          return (
            <Card
              key={index}
              title={card.title}
              type={card.type}
              features={card.features}
              price={
                periodType == 'monthly' ? card.monthlyPrice : card.annualPrice
              }
              paymentPeriod={
                periodType == 'monthly'
                  ? card.monthlyPaymentPeriod
                  : card.annualPaymentPeriod
              }
              cta={card.ctaText}
            />
          )
        })}
      </div>
    </div>
  )
}

// External imports
import React from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

// Styling
const Container = styled.div` ${tw`sm:w-[380px] w-[300px] flex flex-col justify-start items-start p-4 rounded hover:shadow-md bg-neutralBg text-textBase cursor-pointer border-1 border-neutralBg hover:border-blue-500`}; `;
const ContainerTopRow = styled.div` ${tw`w-full flex flex-col items-start border-b justify-between pb-2 text-xl font-semibold`}; `;

export const SettingsCards = ({ icon, header, content, page }) => {
  return (
    <Link to={page}>
      <Container >
        <ContainerTopRow>
          <div className='mb-2 text-4xl'>{icon}</div>
          <h1>{header}</h1>
        </ContainerTopRow>
        <div className='mt-2 h-[40px] text-sm text-textMuted'>{content}</div>
      </Container>
    </Link>
  )
}
// External Imports
import React, { useState, useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Avatar } from '@mui/material'

// Internal Imports
import { addToRecentSearchList } from '../../actions/searchLists'

//Styling
const PopularSearchContainer = styled.div`
  ${tw``}
`
const CompanyCardContainer = styled.div`
  ${tw`max-w-[600px] flex flex-wrap items-center justify-center px-4`};
`
const CompanyCard = styled.div`
  ${tw`w-[125px] h-[45px] border-1 flex items-center justify-center rounded m-2 p-2 shadow-md cursor-pointer hover:bg-neutral hover:mt-1 bg-primaryBg border-textBase text-textBase`}
`
const HeaderText = styled.h1`
  ${tw`text-xl font-semibold flex justify-center my-3 `}
`

const setImgErrorReducer = (state, action) => {
  if (action.type === 'SET_ERROR') {
    const newState = [...state]
    newState[action.payload] = true
    return newState
  } else if (action.type === 'SET_ARRAY') {
    return Array(action.payload).fill(false)
  }
  return state
}

export const PopularSearches = ({ closeModal }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { recentSearchList, fullSearchList } = useSelector(
    (state) => state.searchReducer
  )
  const { hasActivePremiumSubscription } = useSelector(
    (state) => state.watchListReducer
  )
  const [preloadedImages, setPreloadedImages] = useState({})
  const searchLimit = hasActivePremiumSubscription ? 20 : 5
  const [loadImgErr, reducerDispatch] = useReducer(
    setImgErrorReducer,
    null,
    () => Array(recentSearchList.length).fill(false)
  )

  // Find logo by symbol
  const findLogoBySymbol = (symbol) => {
    const foundObject = fullSearchList.find((obj) => obj.symbol === symbol)
    return foundObject?.logo ?? null
  }

  // Preload company logos on page load
  useEffect(() => {
    const preloadImages = () => {
      const images = {}
      const limitedRecentSearchList = recentSearchList.slice(0, searchLimit) // Only preload the 20 most recent searched stocks
      reducerDispatch({
        type: 'SET_ARRAY',
        payload: limitedRecentSearchList.length,
      })
      limitedRecentSearchList.forEach((ticker) => {
        const logo = findLogoBySymbol(ticker)
        if (logo) {
          const image = new Image()
          image.src = logo
          images[ticker] = image
        }
      })
      setPreloadedImages(images)
    }

    preloadImages()
  }, [recentSearchList, fullSearchList])

  return (
    <PopularSearchContainer>
      {recentSearchList.length > 0 && (
        <>
          <div className="flex flex-row justify-center space-x-10">
            <HeaderText className="text-textBase">Recent</HeaderText>
            {/* <HeaderText className='text-textMuted'>Popular</HeaderText> */}
          </div>
          <CompanyCardContainer>
            {recentSearchList.slice(0, searchLimit).map((ticker, idx) => (
              <CompanyCard
                key={ticker}
                onClick={() => {
                  dispatch(addToRecentSearchList(ticker))
                  navigate(`/stockanalysis/${ticker}`)
                  closeModal()
                }}
              >
                {preloadedImages[ticker] && !loadImgErr[idx] ? (
                  <img
                    src={preloadedImages[ticker].src}
                    className='z-0'
                    alt=""
                    width="25px"
                    style={{
                      filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4))',
                      borderRadius: '3px',
                    }}
                    type="image/png"
                    onError={() =>
                      reducerDispatch({ type: 'SET_ERROR', payload: idx })
                    }
                  />
                ) : (
                  <Avatar
                    sx={{
                      bgcolor: '#5f8799',
                      color: '#FFFFFF',
                      width: '32px',
                      height: '32px',
                    }}
                  >
                    {ticker[0]?.toLocaleUpperCase()}
                  </Avatar>
                )}

                <h1 className="text-xs pl-3">{ticker}</h1>
              </CompanyCard>
            ))}
          </CompanyCardContainer>
        </>
      )}
    </PopularSearchContainer>
  )
}
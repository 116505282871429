// External Imports
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
import { BsToggles, BsFunnel } from 'react-icons/bs'
import { ScaleLoader } from 'react-spinners'
import _ from 'lodash'

// Internal Imports
import {
  ContentContainer,
  IconButtons,
  DataTable,
  OptionsModal,
  FiltersModal,
} from '../../components'
import { metricOptions } from '../../data/metricOptions'
import { updateTableMetrics } from '../../actions/userPreferences'
import {
  useFetchStockScreenerQuery,
  useGetUserScreenerSettingsQuery,
  useFetchWatchlistQuery,
} from '../../rtk'

// Styling
const Container = styled.div`
  ${tw`w-full flex flex-col items-center p-6`};
`
const WarningWrapper = styled.div`
  ${tw`flex flex-col items-center justify-items-center p-5`};
`
const WarningText = styled.div`
  ${tw`text-center pt-3`};
`

// Page to accept user selected filters and display the results
export const Screener = () => {
  const [screenerResults, setScreenerResults] = useState([])
  const { tableMetrics, tableRows } = useSelector(
    (state) => state.userPreferenceReducer
  )
  const [tableMetricsCpy, setTableMetricsCpy] = useState([])
  const [openSettingsModal, setOpenSettingsModal] = useState(false)
  const [openFiltersModal, setOpenFiltersModal] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [dataTableTotalPage, setDataTableTotalPage] = useState(0)
  const [datatableCurrentPage, setDatatableCurrentPage] = useState(1)
  const [sortField, setSortField] = useState('symbol')
  const [sortOrder, setSortOrder] = useState(1)
  const dispatch = useDispatch()
  const { data: watchlistSymbols, isFetching: isFetchingWatchlistSymbols } =
    useFetchWatchlistQuery()

  const { data: userScreenerSettings } = useGetUserScreenerSettingsQuery()

  const formatted = selectedFilters.map((filter) => {
    const operator = filter.operator === 'Greater than' ? 'gt' : 'lt'
    const option = metricOptions.find(
      (option) => option.displayedText === filter.name
    )
    const value = parseFloat(filter.value)
    return {
      operator,
      field: option?.field ?? null,
      value,
      category: option?.category ?? null,
    }
  })
  const { data, isFetching } = useFetchStockScreenerQuery({
    searchCriteria: formatted,
    tableMetrics,
    page: datatableCurrentPage,
    sortField,
    sortOrder,
    tableRows,
  })
  useEffect(() => {
    const screenerSettings = userScreenerSettings?.data ?? []
    setSelectedFilters(screenerSettings)
  }, [userScreenerSettings])

  useEffect(() => {
    setScreenerResults(data?.data.data ?? [])
    setDataTableTotalPage(data?.data.totalPages ?? 0)
  }, [data])

  useEffect(() => {
    setTableMetricsCpy(tableMetrics)
  }, [tableMetrics])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const groupedOptions = metricOptions.reduce((result, option) => {
    if (option.isOptional) {
      if (!result[option.category]) {
        result[option.category] = []
      }
      result[option.category].push(option)
    }
    return result
  }, {})

  return (
    <Container>
      {
        <ContentContainer
          Header={'Screener'}
          maxWidth={'2500px'}
          Icons={
            <>
              <IconButtons
                action={() => setOpenFiltersModal(true)}
                icon={<BsFunnel />}
                text={'Filters'}
              />
              <FiltersModal
                open={openFiltersModal}
                close={() => {
                  setOpenFiltersModal()
                }}
                type="settings"
                header="Filters"
                icon={<BsFunnel />}
                callToAction={<div>Select filters to be applied</div>}
                tableMetrics={tableMetrics}
                setSelectedFilters={setSelectedFilters}
                setScreenerResults={setScreenerResults}
                selectedFilters={selectedFilters}
              />
              <IconButtons
                action={() => setOpenSettingsModal(true)}
                icon={<BsToggles />}
                text={'Settings'}
              />
              <OptionsModal
                open={openSettingsModal}
                close={() => {
                  dispatch(updateTableMetrics(tableMetricsCpy)).finally(() =>
                    setOpenSettingsModal(false)
                  )
                }}
                type="settings"
                header="Settings"
                icon={<BsToggles />}
                callToAction={
                  <div>Select columns to be displayed in the table</div>
                }
                options={groupedOptions}
                selectedOptions={tableMetricsCpy}
                setSelectedOptions={setTableMetricsCpy}
              />
            </>
          }
          Content={
            <>
              {(isFetching || isFetchingWatchlistSymbols) && (
                <div className="min-h-[200px] flex items-center justify-center">
                  <ScaleLoader size={5} color={'#218B82'} />
                </div>
              )}
              {!isFetching && !isFetchingWatchlistSymbols && (
                <>
                  {!_.isEmpty(screenerResults) && (
                    <DataTable
                      tableData={screenerResults}
                      selectedColumns={tableMetrics}
                      columns={metricOptions}
                      totalPages={dataTableTotalPage}
                      currentPage={datatableCurrentPage}
                      setCurrentPage={setDatatableCurrentPage}
                      sortField={sortField}
                      setSortField={setSortField}
                      sortOrder={sortOrder}
                      setSortOrder={setSortOrder}
                      tableRows={tableRows}
                      watchList={watchlistSymbols?.data ?? []}
                    />
                  )}
                  {_.isEmpty(screenerResults) && (
                    <WarningWrapper
                      className="cursor-pointer"
                      onClick={() => setOpenFiltersModal(true)}
                    >
                      <BsFunnel size={40} />
                      <WarningText>
                        {selectedFilters.length
                          ? 'No stocks found'
                          : 'Select filters to screen for stocks'}
                      </WarningText>
                    </WarningWrapper>
                  )}
                </>
              )}
            </>
          }
        />
      }
    </Container>
  )
}

// External imports
import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import tw from 'twin.macro'
import { AnimatePresence, motion } from 'framer-motion'
import { IoClose, IoSearch } from 'react-icons/io5'
import { useMediaQuery } from 'react-responsive'

// Internal imports
import { IconButtons, Company } from '../../components/index'
import { useDebounce, useClickOutside } from '../../hooks'
import { addToRecentSearchList } from '../../actions/searchLists'
import { PlanRestrictionModal } from '../modals/planRestrictionModal'
import { Avatar } from '@mui/material'
import { deviceSize } from '../../data/devices'
import { useNavigate } from 'react-router-dom'
import { nameConversions } from '../../data/metricOptions'

// Styling
const SearchSection = styled.div`
  ${tw`relative w-full max-w-[2500px] flex flex-col justify-center items-center bg-primaryBg text-textBase hover:shadow-[0px 1px 6px 1px rgba(0, 0, 0, 0.2)] shadow-[0px 0px 5px 1px rgba(0, 0, 0, 0.1)] rounded border-1 border-textBase z-[22px]`};
`
const SearchBarContainer = styled(motion.div)`
  ${tw`w-full flex flex-row rounded`};
`
const SearchInputContainer = styled.div`
  ${tw`w-full min-h-[50px] flex items-center py-0.5 px-2`};
`
const SearchInput = styled.input`
  ${tw`w-full h-full outline-none border-none text-sm  bg-[transparent]`};
  &::placeholder {
    transition: all 500ms ease-in-out;
    color: var(--textMuted);
  }
  &:hover&::placeholder {
    color: var(--textBase);
  }
`
const SearchIcon = styled.span`
  ${tw`text-lg mr-2.5 w-[35px] h-[35px] flex justify-center items-center rounded-full text-textBase `};
`
const CloseIcon = styled(motion.span)`
  ${tw`text-xl align-middle cursor-pointer text-textMuted hover:text-textBase`};
  transition: all 200ms ease-in-out;
`

const DropDownContent = styled.div`
  ${tw`flex flex-col w-full items-center rounded justify-center overflow-x-hidden overflow-y-auto bg-primaryBg shadow-md border-textBase border-1 absolute top-full mt-2 min-h-[57px] z-30`};
`
const SearchContent = styled.div`
  ${tw`w-full h-full flex flex-col p-[0.5] overflow-y-auto`};
`
const LoadingWrapper = styled.div`
  ${tw`w-full h-[55px] flex items-center justify-center py-4`};
`
const WarningMessage = styled.span`
  ${tw`text-textMuted text-sm flex self-center justify-self-center`};
`

const glowAnimation = keyframes`
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 200%;
  }
`

const GlowingBackground = styled.div`
  ${tw`absolute rounded opacity-[0.25]`};
  inset: -0.1rem;
  background: linear-gradient(
    90deg,
    #047857,
    #0369a1,
    #ff22a3,
    #fce93f,
    #047857
  );
  background-size: 200%;
  filter: blur(10px);
  animation: ${glowAnimation} 5s linear infinite;
`

export const SearchBar = ({
  onSearchClick,
  close,
  searchQuery,
  setSearchQuery,
}) => {
  const dispatch = useDispatch()
  const inputRef = useRef()
  const defaultPlaceholderText = 'Search for stocks...'
  const [isOpen, setIsOpen] = useState(false)
  // const [searchQuery, setSearchQuery] = useState('')
  const [noCompanies, setNoCompanies] = useState(false)
  const [placeholderText, setPlaceholderText] = useState('Search for stocks...')
  const [filteredCompanies, setFilteredCompanies] = useState([])
  const { hasActivePremiumSubscription } = useSelector(
    (state) => state.watchListReducer
  )
  const [showPlanRestriction, setShowPlanRestriction] = useState(false)
  const { recentSearchList, fullSearchList } = useSelector(
    (state) => state.searchReducer
  )
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)

  const changeHandler = (e) => {
    e.preventDefault()
    if (hasActivePremiumSubscription || recentSearchList.length < 5) {
      if (e.target.value.trim() === '') {
        setIsLoading(true)
        setNoCompanies(false)
        setSearchQuery('')
        setFilteredCompanies([])
        setPlaceholderText(defaultPlaceholderText)
        if (inputRef.current) inputRef.current.value = ''
        setIsOpen(false)
      } else {
        setNoCompanies(false)
        setSearchQuery(e.target.value)
      }
    } else {
      if (recentSearchList.length >= 5) {
        setShowPlanRestriction(true)
      }
    }
  }

  const collapseContainer = () => {
    setIsLoading(true)
    setTimeout(() => {
      setIsOpen(false)
      setSearchQuery('')
      setFilteredCompanies([])
      setNoCompanies(false)
      setPlaceholderText(defaultPlaceholderText)
      if (inputRef.current) inputRef.current.value = ''
    }, 10)
  }

  const searchCompanies = async () => {
    setIsLoading(true)
    if (!searchQuery || searchQuery.trim() === '') return

    const sortedList = fullSearchList.sort((a, b) =>
      a.symbol.localeCompare(b.symbol)
    )

    const tickerMatches = sortedList.filter((company) =>
      company.symbol.toUpperCase().startsWith(searchQuery.toUpperCase())
    )

    const sortedByNameList = fullSearchList.sort((a, b) => {
      const companyNameA = a?.companyName?.toUpperCase() || ''
      const companyNameB = b?.companyName?.toUpperCase() || ''
      return companyNameA.localeCompare(companyNameB)
    })

    const nameStartsWithMatches = sortedByNameList.filter((company) =>
      company?.companyName?.toUpperCase().startsWith(searchQuery.toUpperCase())
    )

    const remainingNameMatches = sortedByNameList
      .filter((company) => !nameStartsWithMatches.includes(company))
      .filter((company) =>
        company?.companyName?.toUpperCase().includes(searchQuery.toUpperCase())
      )

    // Combine the results
    const uniqueCompanies = new Set([
      ...tickerMatches,
      ...nameStartsWithMatches,
      ...remainingNameMatches,
    ])

    const filteredList = Array.from(uniqueCompanies).slice(0, 15)

    setFilteredCompanies(filteredList)

    if (filteredList.length === 0) {
      setNoCompanies(true)
    } else {
      setNoCompanies(false)
      setFilteredCompanies(filteredList.slice(0, 15))
    }

    setIsLoading(false)
  }

  useDebounce(searchQuery, 500, searchCompanies)

  return (
    <div className="relative max-w-[2500px] w-full">
      <GlowingBackground />
      <SearchSection
        ref={useClickOutside(collapseContainer)}
        className="relative"
      >
        <SearchBarContainer>
          <SearchInputContainer>
            <div>
              <SearchIcon>
                <IoSearch />
              </SearchIcon>
            </div>
            <SearchInput
              placeholder={placeholderText}
              onFocus={() => {
                setPlaceholderText('Type the name or ticker to search...')
              }}
              onBlur={() => {
                setPlaceholderText(defaultPlaceholderText)
              }}
              onClick={onSearchClick}
              onInput={() => setIsOpen(true)}
              ref={inputRef}
              value={searchQuery}
              onChange={changeHandler}
            />
            <AnimatePresence>
              {isOpen && (
                <CloseIcon
                  key="close-icon"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={collapseContainer}
                  transition={{ duration: 0.2 }}
                >
                  <IconButtons
                    action={() => collapseContainer()}
                    icon={<IoClose />}
                    text={'Close'}
                  />
                </CloseIcon>
              )}
            </AnimatePresence>
          </SearchInputContainer>
        </SearchBarContainer>
        {isOpen && !isLoading && (
          <DropDownContent>
            <SearchContent>
              {noCompanies && (
                <LoadingWrapper>
                  <WarningMessage>No stocks found</WarningMessage>
                </LoadingWrapper>
              )}
              {!noCompanies && (
                <div className="overflow-auto max-h-[70vh] divide-y-1 divide-neutralBg ">
                  {filteredCompanies.length > 0 &&
                    filteredCompanies.map((item) => (
                      <Company
                        key={item['_id']}
                        logo={item.logo}
                        ticker={item.symbol}
                        name={item.companyName}
                        exchange={item.exchangeShortName}
                        onClick={() => {
                          collapseContainer()
                          dispatch(addToRecentSearchList(item.symbol))
                          navigate(`/stockanalysis/${item.symbol}`)
                          close(false)
                        }}
                      />
                    ))}
                </div>
              )}
            </SearchContent>
          </DropDownContent>
        )}
        <PlanRestrictionModal
          open={showPlanRestriction}
          close={() => {
            setShowPlanRestriction()
          }}
        />
      </SearchSection>
    </div>
  )
}
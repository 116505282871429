// External imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from 'styled-components';
import tw from "twin.macro";
import _ from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { IoSearch } from 'react-icons/io5'
import { motion } from 'framer-motion';

// Internal imports
import { LandingPageNavbar } from '../../components';
import { LandingPageHeroImage, WatchlistImage, FilterHeroImage } from '../../assets';
import { Footer } from '../footer';
import { useGetTotalUsersQuery } from '../../rtk';
import { landingPageTotalUserHelper } from '../../utils/misc';
import { landingPage, quotes, paymentPlans } from '../../data/text'
import { nameConversions } from '../../data/metricOptions';
import { FormButton } from '../../components'
import { FeatureCard } from '../../components/cards/featureCard'

// Styling
const motionConfig = (delay = 0) => ({
  initial: { opacity: 0, y: 50 },
  whileInView: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -50 }, // New key for exit animation
  transition: { duration: 0.8, delay },
  viewport: { once: true },
});

const MovingComponent = (props) => (
  <motion.div {...motionConfig(props.delay)} {...props} />
);


const gradientAnimation = keyframes`
  0%   {background-position: 0% 50%;}
  20%  {background-position: 25% 50%;}
  40%  {background-position: 50% 50%;}
  60%  {background-position: 75% 50%;}
  80%  {background-position: 100% 50%;}
  100% {background-position: 0% 50%;}
`;

const GradientText = styled.span`
  ${tw`text-transparent font-semibold xl:text-4xl text-3xl`}
  background-clip: text;
  background-image: linear-gradient(45deg, #218b5d, #295ab4, #ff8c00, #ff69b4, #32cd32); 
  background-size: 200% 200%;
  animation: ${gradientAnimation} 3s ease infinite;
`;

const flash = keyframes`
  0%, 50% {opacity: 0;}
  100% {opacity: 1;}
`;

const BlinkingCaret = styled.span`
  ${tw`ml-0.5 text-textBase`}
  animation: ${flash} 1s linear infinite;
`;

const GlassBackground = styled.div` 
  ${tw`flex flex-col items-center justify-center space-y-6 rounded-lg p-4`}
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.2);
`;

const CompanyCard = styled.div`
  ${tw`w-[105px] h-[45px] border-1 flex items-center justify-center rounded m-2 p-2 shadow-md hover:mt-1 bg-primaryBg border-textMuted text-textBase`}
`;

const Letter = styled.span`
  display: inline-block;
  opacity: 0;
  transform: translateY(100%);
  animation: ${({ delay }) => `fadeInUp 0.5s ${delay}s forwards`};
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const AnimatedHeaderText = ({ text }) => {
  return (
    <p>
      {text.split('').map((char, index) => (
        <Letter key={index} delay={index * 0.1}>
          {char === ' ' ? '\u00A0' : char}
        </Letter>
      ))}
    </p>
  );
};

const HeaderText = ({ Tag, Paragraph }) => {
  return (
    <div className='flex flex-col text-center space-y-5 w-full max-w-[750px]'>
      <MovingComponent className='lg:text-3xl text-3xl font-semibold text-textBase'>{Tag}</MovingComponent>
      <MovingComponent className='text-sm font-thin text-textMuted'>
        {Paragraph}
      </MovingComponent>
    </div>
  );
};

const LandingPageSection = ({ tag, paragraph, cta, image, width, height }) => (
  <div className={`w-full max-w-[2500px] min-h-fit flex flex-col items-center justify-center bg-primaryBg px-5 py-20 space-y-10`} style={{ height }}>
    <HeaderText Tag={tag} Paragraph={paragraph} />
    <MovingComponent className='w-full flex items-center justify-center'>{cta}</MovingComponent>
    {image && 
      <MovingComponent className='w-fit flex items-center justify-center'>
        <GlassBackground className={`w-fit h-fit`}>
          <div className='w-full h-full rounded-lg border-1 border-textBase'>
            {image}
          </div>
        </GlassBackground>
      </MovingComponent>
    }
  </div>
);

export const LandingPage = (error) => {
  const navigate = useNavigate();
  const { logout, user } = useAuth0();
  const { data, isFetching, isError } = useGetTotalUsersQuery();

  const doLogout = () => {
    localStorage.removeItem('token');
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  if (user && !user.email_verified) {
    doLogout();
  }

  return (
    <div className='w-full flex flex-col justify-between items-center overflow-y-auto text-textBase border-textBase bg-primaryBg'>
      <div className='w-full flex text-textBase'>
        <LandingPageNavbar className='flex' />
      </div>

      <LandingPageSection
        tag={
          <div className='uppercase font-normal xl:text-5xl text-4xl'>
            {/* <AnimatedHeaderText text="Next generation"/> */}
            Next generation<br/>
            <GradientText>
              investment analysis
            </GradientText>
          </div>
        }
        paragraph={
          <p>{landingPage.overviewLine1}<br />{landingPage.overviewLine2}</p>
        }
        cta={
          <FormButton maxWidth='200px' onClick={() => navigate('/signup-terms')} text={'Try for free'} />
        }
        image={null}
        width={'650px'}
        height={`calc(100vh - 101px)`}
      />

      <div className='w-full min-h-[40px] p-2 flex items-center justify-center text-white bg-textBase font-semibold'>
        {!isFetching && !isError && <p className='text-base'>{landingPageTotalUserHelper(data.data)}</p>}
      </div>

      {/* Search section */}
      <div className={`w-full min-h-screen flex flex-col items-center justify-center bg-primaryBg sm:p-20 px-5 py-20 space-y-20 border-b-1 border-textBase`}>
        <HeaderText Tag={landingPage.searchTagLine} Paragraph={landingPage.searchParagraphText} />

        <MovingComponent className='flex flex-col gap-4'>
          <GlassBackground className='max-w-[1100px] h-[40px] relative'>
            <div className='w-full flex flex-row items-center justify-start space-x-5 text-sm text-textMuted'>
              <IoSearch />
              <span>{landingPage.searchOptionality} <BlinkingCaret>|</BlinkingCaret></span>
            </div>
          </GlassBackground>
          <div className='w-full items-center justify-center flex relative'>
            <div className='items-center justify-center max-w-[600px] flex flex-wrap p-4 relative'>
              {['AAPL', 'ADBE', 'AMZN', 'GOOG', 'MA', 'META', 'MSFT', 'NFLX', 'NVDA', 'PYPL', 'SBUX', 'TSLA'].map((ticker, idx) => (
                <MovingComponent key={ticker} delay={idx * 0.05}>
                  <CompanyCard key={ticker}>
                    <img
                      src={`https://financialmodelingprep.com/image-stock/${ticker}.png`} alt="" width="25px"
                      style={{ filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4))', borderRadius: '3px' }}
                      type="image/png"
                    />
                    <h1 className="text-xs pl-3">{ticker}</h1>
                  </CompanyCard>
                </MovingComponent>
              ))}
            </div>
          </div>
        </MovingComponent>

        <MovingComponent className='flex flex-col space-y-4 relative'>
          <span className='text-lg font-thin text-textBase items-center justify-center flex text-center w-full max-w-[500px]'>Financial markets in</span>
          <div className='grid md:grid-cols-4 grid-cols-2 items-center justify-center relative'>
            {['US', 'CA', 'HK', 'JP'].map((shortName, idx) => {
              const country = nameConversions.find(item => item.shortName === shortName);
              if (!country) return null;

              return (
                <MovingComponent key={shortName} delay={idx * 0.3}>
                  <span className='flex flex-col text-sm font-thin text-textBase items-center space-y-2 w-[120px] p-5'>
                    <img src={`https://flagcdn.com/h40/${shortName.toLowerCase()}.png`} alt="Flag" type="image/png" className='shadow-lg border-1' />
                    <span className='text-xs font-thin'>{country.name} </span>
                  </span>
                </MovingComponent>
              );
            })}
          </div>
        </MovingComponent>
      </div>

      <div className='w-full'>

        {/* Analysis */}
        <LandingPageSection
          tag={landingPage.analysisTagLine}
          paragraph={landingPage.analysisParagraphText}
          image={<img src={LandingPageHeroImage} alt="Analysis" className='rounded-lg sm:h-[775px]' />}
          width={'650px'}
        />

        {/* Watchlist */}
        <LandingPageSection
          tag={landingPage.watchListTagLine}
          paragraph={landingPage.watchListParagraphText}
          image={<img src={WatchlistImage} alt="Watchlist" className='rounded-lg 2xl:h-[535px]' />}
          width={'1100px'}
        />

        {/* Screener */}
        <LandingPageSection
          tag={landingPage.screenerTagLine}
          paragraph={landingPage.screenerParagraphText}
          image={<img src={FilterHeroImage} alt="Filters" className='rounded-lg sm:h-[490px]' />}
          width={'650px'}
        />

      </div>
      <div className='w-full flex xl:flex-row flex-col xl:space-x-10 space-x-0 xl:justify-center xl:items-start items-center bg-primaryBg text-textBase p-20 border-y-1 border-textBase'>
        {quotes.map((quote, index) => (
          <MovingComponent key={index} delay={index * 0.3} className='w-[400px] flex flex-col xl:text-left text-center px-8 py-5'>
            <span className='lg:text-lg md:text-md font-bold'>{quote.text}</span>
            <span className='text-textMuted font-thin text-md'>- {quote.author}</span>
            {/* <img src={`image-${index}.png`} alt={`${quote.author} image`} /> */}
          </MovingComponent>
        ))}
      </div>

      <div className='w-full flex flex-col justify-evenly items-center bg-primaryBg p-20 space-y-10'>
        <MovingComponent className='lg:text-3xl text-3xl font-bold text-textBase'>Pricing</MovingComponent>
        <MovingComponent className='flex flex-row relative'>
          {paymentPlans.map((card, index) => {
            return (
              <FeatureCard
                key={index}
                title={card.title}
                type={card.type}
                features={card.features}
                price={card.monthlyPrice}
                paymentPeriod={card.monthlyPaymentPeriod}
                cta={<FormButton onClick={() => navigate('/signup-terms')} text={'Try for free'} />}
              />
            )
          })}
        </MovingComponent>
      </div>

      <Footer />
    </div>
  );
};
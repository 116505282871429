// External imports
import styled from 'styled-components'
import tw from 'twin.macro'
import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { PaymentDetailsCard } from '../../components'
import { cancelSubscriptionById } from '../../actions/payment'
import { useDispatch } from 'react-redux'
import { useState } from 'react'

// Styling
const CardContainer = styled.div`
  ${tw`w-[fit-content] flex flex-col justify-center items-center rounded p-2 `};
`

export const StripeContainer = ({
  stripePromise,
  options,
  paymentCompleted,
  cancelCompleted,
  subscriptionId,
}) => {
  const dispatch = useDispatch()
  const [cancelled, setCancelled] = useState(false)
  const returnToPage = () => {
    paymentCompleted(true)
  }

  const doCancelSubscription = () => {
    dispatch(cancelSubscriptionById(subscriptionId)).then((res, error) => {
      if (res) {
        setCancelled(true)
        cancelCompleted(true)
      } else {
        cancelCompleted(false)
      }
    })
  }

  return (
    <CardContainer>
      <Elements stripe={stripePromise} options={options}>
        {!cancelled && (
          <PaymentDetailsCard
            buttonText="Confirm"
            successfulPayment={() => {
              returnToPage()
            }}
            secondaryButtonText={'Cancel'}
            secondarySuccessfulPayment={doCancelSubscription}
          />
        )}
      </Elements>
    </CardContainer>
  )
}

// External imports
import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { useTheme } from 'styled-components';
import dayjs from 'dayjs';

// Register chart.js plugins
Chart.register(...registerables);

// use to save chartColour and trigger plugin update
// index 0: chartColour
// index 1: chartTimeframe
/** @type {[string, string]} */
const memory = [];

const hashSet = new Set(['1D', '5D', '1M']);

// format x-axis ticks when chartTimeframe is MAX
const maxLabelFormatter = (chartData) => {
  const {labels} = chartData;
  const right = Number(dayjs(labels[labels.length - 1]).format('YYYY'));
  const left = Number(dayjs(labels[0]).format('YYYY'));
  const hashSet = new Set();
  const beenShown = new Set();
  for (let i = right; i >= left; i -= 2) {
    hashSet.add(i.toString());
  }
  const arr = [];
  for (let i = 0; i < labels.length; i++) {
    let label= dayjs(labels[i]).format('YYYY');
    if (hashSet.has(label) && !beenShown.has(label) && i !== 0) {
      arr.push(label)
      beenShown.add(label);
    }
    else arr.push(null);
  }
  return index => arr[index];
}

export const PriceGraph = ({ chartData, chartColour, chartFillColour, isStacked, labelDisplay, priceChartTimeframe }) => {
  const palette = useTheme();

  memory[0] = chartColour;
  memory[1] = priceChartTimeframe;

  const chartRef = useRef();

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      filler: {
        drawTime: 'beforeDraw'
      },
      tooltip: {
        enabled: false,
      },
      chartColour,
      backgroundDots: true,
    },
    scales: {
      x: {
        stacked: isStacked,
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          color: palette.colors.borderSecondary,
          borderColor: palette.colors.borderSecondary,
        },
        ticks: {
          display: true,
          color: palette.colors.textMuted,
          autoSkip: true,
          callback: (priceChartTimeframe === 'MAX' || priceChartTimeframe === '10Y') ? maxLabelFormatter(chartData) : (value, index) => {
            if (value === 0) {
              return null;
            }
            let label = dayjs(new Date(chartData.labels[index])).format(labelDisplay)
            let prevLabel = dayjs(new Date(chartData.labels[index - 1])).format(labelDisplay)
            if (index === 0 || label !== prevLabel) {
              return label;
            }
            return null;
          }
        }
      },
      y: {
        stacked: isStacked,
        beginAtZero: false,
        grid: {
          display: false,
          drawBorder: false,
          borderColor: palette.colors.borderSecondary,
          drawOnChartArea: false,
        },
        ticks: {
          display: true,
          color: palette.colors.textMuted,
        }
      },
      y1: {
        stacked: false,
        beginAtZero: false,
        position: 'right',
        grid: {
          display: false,
          drawBorder: false,
          borderColor: palette.colors.borderSecondary,
          drawOnChartArea: false,
        },
        ticks: {
          display: false,
          color: palette.colors.textMuted,
        },
        min: 0,
        max: Math.max(...chartData.volumeData) * 5  // Adjust the max value for the volume data to scale it down to 20%
      }
    },
    elements: {
      line: {
        tension: 0.4,
      },
      bar: {
        borderWidth: 0,
      }
    },
  };

  const hoverLinePlugin = {
    id: 'hoverLine',
    tooltip: null,
    beforeInit: (chart) => {
      const canvas = chart.canvas;
      hoverLinePlugin.tooltip = document.createElement('div');
      hoverLinePlugin.tooltip.id = 'CanvasPriceGraph@#!'
      const tooltip = hoverLinePlugin.tooltip;
      tooltip.style.position = 'absolute';
      tooltip.style.background = '#1f232ecc';
      tooltip.style.padding = '5px 10px';
      tooltip.style.borderRadius = '4px';
      tooltip.style.display = 'none';
      tooltip.style.boxShadow = '-3px 3px 5px rgba(0, 0, 0, 0.2)';
      tooltip.style.zIndex = 25;
  
      document.body.appendChild(tooltip);
  
      canvas.addEventListener('mousemove', (event) => {
        const { left } = canvas.getBoundingClientRect();
        const mouseX = event.clientX - left;
  
        // Get the x-coordinate of datapoint 0
        const datapointX0 = chart.scales.x.getPixelForValue(0);
  
        // Only update the chart's mouseX if mouseX is greater than or equal to datapointX0
        if (mouseX >= datapointX0 && mouseX <= chart.chartArea.right) {
          chart.mouseX = mouseX;
          chart.update();
  
          // Update tooltip position and content
          const dataIndex = chart.scales.x.getValueForPixel(mouseX);
          const priceDataset = chart.data.datasets[0];
          const volumeDataset = chart.data.datasets[1];
          if (priceDataset && priceDataset.data[dataIndex] !== undefined) {
            tooltip.style.display = 'none';
            tooltip.style.left = '';
            tooltip.style.right = '';
  
            const priceValue = priceDataset.data[dataIndex].toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            const volumeValue = volumeDataset.data[dataIndex].toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            const xLabel = chart.data.labels[dataIndex];
  
            // Check if the mouse Y is in the top half or bottom half of the chart
            const chartHeight = canvas.getBoundingClientRect().height;
            const mouseY = event.clientY - canvas.getBoundingClientRect().top;
            if (mouseY < chartHeight / 2.7) {
              // If mouse Y is in the top half, place tooltip below the cursor
              tooltip.style.top = event.pageY + 35 + 'px';
            } else {
              // If mouse Y is in the bottom half, place tooltip above the cursor
              tooltip.style.top = event.pageY - tooltip.clientHeight - 95 + 'px';
            }
  
            // Check if the tooltip would extend beyond the right edge of the chart
            const chartRight = canvas.getBoundingClientRect().right;
            if (event.clientX > chartRight / 1.6) {
              tooltip.style.left = '';
              tooltip.style.right = window.innerWidth - event.x + 35 + 'px';
            } else {
              tooltip.style.right = '';
              tooltip.style.left = event.x + 35 + 'px';
            }
  
            tooltip.style.display = 'block';
  
            tooltip.innerHTML = `
            <div style='position: relative; padding: 4px; width: 140px;'>
              <div style='display: flex; flex-direction: row; font-size: 10px; color: #b0b2b4; margin-bottom: 4px;'>
                ${hashSet.has(memory[1]) ? 
                  "<div style='margin-right: 5px;'>" + dayjs(xLabel).format('HH:mm') + "," + "</div>" :''
                }
                <div>${dayjs(xLabel).format('DD MMM YYYY')}</div>
              </div>
              <div style='font-size: 12px; font-weight: 120; color: #ffffff; display: flex; justify-content: space-between; margin-bottom: 2px;'>
                <span>Price</span>
                <span>$${priceValue}</span>
              </div>
              <div style='font-size: 12px; font-weight: 100; color: #b0b2b4; display: flex; justify-content: space-between;'>
                <span>Volume</span>
                <span>${volumeValue}</span>
              </div>
            </div>`;
            tooltip.style.display = 'block';
          } else {
            tooltip.style.display = 'none';
          }
        }
      });
  
      // Hide the tooltip and reset mouseX when leaving the canvas
      canvas.addEventListener('mouseleave', () => {
        hoverLinePlugin.tooltip.style.display = 'none';
        chart.mouseX = undefined;
        chart.update();
      });
  
      // Hide the tooltip when window is resized
      window.addEventListener('resize', () => {
        hoverLinePlugin.tooltip.style.display = 'none';
        chart.mouseX = undefined;
        chart.update();
      });
    },
  
    beforeDraw: (chart) => {
      const { ctx, chartArea: { bottom, top, left, right }, mouseX } = chart;
      if (hoverLinePlugin.tooltip.style.display === 'none') {
        return;
      }
      if (mouseX !== undefined) {
        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#D1D4DC';
  
        // Get the x-coordinate of datapoint 0
        const datapointX0 = chart.scales.x.getPixelForValue(0);
  
        // Use Math.max to ensure the hoverline doesn't go to the left of datapoint 0
        const hoverlineX = Math.max(mouseX, datapointX0);
  
        // Find the corresponding dataset index and data point index
        const dataIndex = chart.scales.x.getValueForPixel(hoverlineX);
  
        // Retrieve the y-coordinate value for the given x-coordinate
        const pointElement = chartRef.current.getDatasetMeta(0).data[dataIndex];
        const hoveredDataX = pointElement?.x;
        const hoveredDataY = pointElement?.y;
  
        ctx.moveTo(hoveredDataX, bottom);
        ctx.lineTo(hoveredDataX, top);
        ctx.stroke();
        ctx.closePath();
  
        ctx.beginPath();
        ctx.moveTo(left, hoveredDataY);
        ctx.lineTo(right, hoveredDataY);
        ctx.stroke();
        ctx.closePath();
  
        ctx.beginPath();
        ctx.strokeStyle = memory[0];
        ctx.arc(hoveredDataX, pointElement?.y, 3, 0 * Math.PI, 2 * Math.PI);
        ctx.fillStyle = memory[0];
        ctx.fill();
        ctx.closePath();
      }
    },
  
    beforeDestroy: () => {
      hoverLinePlugin.tooltip.style.display = 'none';
    },
  
  };

  const backgroundDotsPlugin = {
    id: 'backgroundDots',
    beforeDraw: (chart) => {
      const { ctx, chartArea: { left, right, top, bottom } } = chart;
      ctx.save();
      ctx.fillStyle = 'rgba(128, 128, 128, 0.3)' //palette.colors.chartDots

      const dotSpacing = 5;
      const dotRadius = 0.5;

      const dotCanvas = document.createElement('canvas');
      dotCanvas.width = dotSpacing;
      dotCanvas.height = dotSpacing;
      const dotCtx = dotCanvas.getContext('2d');
  
      dotCtx.beginPath();
      dotCtx.arc(dotSpacing / 2, dotSpacing / 2, dotRadius, 0, 2 * Math.PI);
      dotCtx.fillStyle = ctx.fillStyle;
      dotCtx.fill();
  
      const pattern = ctx.createPattern(dotCanvas, 'repeat');
      ctx.fillStyle = pattern;
  
      ctx.fillRect(left, top, right - left, bottom - top);
      ctx.restore();
    }
  };

  const data = {
    labels: chartData.labels,
    datasets: [
      {
        type: 'line',
        label: 'Price',
        data: chartData.priceData,
        pointRadius: 0,
        borderWidth: 1,
        borderColor: chartColour,
        fill: false,
        yAxisID: 'y',
      },
      {
        type: 'bar',
        label: 'Volume',
        data: chartData.volumeData,
        backgroundColor: 'rgba(167, 167, 167, 0.5)',
        yAxisID: 'y1',
      },
    ],
  };

  return chartData.labels ? 
    <Line id='canvas' ref={chartRef} data={data} options={options} 
    plugins={
      [
        hoverLinePlugin, 
        backgroundDotsPlugin
      ]}/> 
    : null;
}
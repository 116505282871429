import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import BeatLoader from 'react-spinners/BeatLoader'

const primaryStyle = 'border-1 border-textBase rounded flex items-center justify-center text-sm font-semibold'
const secondaryStyle = 'text-textPositive bg-none border-textPositive hover:bg-neutralBg hover:opacity-90'
const negativeStyle = 'text-textNegative bg-primaryBg border-textNegative hover:bg-neutralBg hover:opacity-90'
const disabledStyle = 'text-textButtonActive bg-activeBg border-black pointer-events-none'
const normalStyle = 'flex w-full py-2 rounded items-center justify-center font-semibold border-1'
const hoverStyle = 'hover:shadow-md'

const StyledButton = styled.button` 
  ${tw`${normalStyle} ${hoverStyle} text-sm min-h-[40px]`};
  max-width: ${({ maxWidth }) => maxWidth};
`

export const FormButton = ({ text, onClick, maxWidth, width, isLoading, isNegative, isDisabled, isSecondary, textColor = null, type = 'submit'}) => {
  const buttonStyle = `${
    isDisabled ? disabledStyle
    : isNegative ? negativeStyle
    : isSecondary ? secondaryStyle
    : primaryStyle
  }`
  const loaderColor = isNegative ? 'var(--textNegative)' : isSecondary ? 'var(--textPositive)': '#ffffff'

  return (
    <StyledButton className={buttonStyle} type={type} onClick={onClick} style={{ width }} disabled={isDisabled} maxWidth={maxWidth}>
      {isLoading ? (
        <BeatLoader size={8} color={loaderColor} />
      ) : (
        <span className={textColor}>{text}</span>
      )}
    </StyledButton>
  )
}